import { protectedApi, publicApi } from '../../services/axios'

// Logout API
export const logOut = async () => {
  try {
    const response = await publicApi.post('/pass/logout/');
    return response;
  } catch (error) {
    return error.response; // Hata durumunda okunabilir bir data elde etmek için error.response dönülmeli!
  }
}


export const getUser = async (id) => {
  try {
    const response = await protectedApi.get(`management/user/${id}`);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const isTokenValid = async (token) => {
  // SEND access_token to api
  const paylaod = {
    token: token
  }
  try {
    await publicApi.post('pass/token/verify/', paylaod)
    return true
  } catch (error) {
    return false
  }

}