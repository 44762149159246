import { protectedApi } from '../../services/axios'


export const getDictionaries = async ({ cancelToken }) => {
  try {
    const response = await protectedApi.get('management/dictionaries', { cancelToken: cancelToken });
    return response;
  } catch (error) {
    return error.response;
  }
}

export const createDictionary = async (payload) => {
  try {
    const response = await protectedApi.post('management/dictionaries', payload);
    return response;
  } catch (error) {
    return error.response;
  }
}
export const getDictionary = async (id) => {
  try {
    const response = await protectedApi.get(`management/dictionary/${id}`);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const updateDictionary = async (id, payload) => {
  try {
    const response = await protectedApi.put(`management/dictionary/${id}`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
}
export const deleteDictionary = async (id) => {
  try {
    const response = await protectedApi.put(`management/dictionary/${id}`, {isDeleted: true});
    return response;
  } catch (error) {
    return error.response;
  }
}