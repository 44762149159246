import React, { useEffect, useState } from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRedux } from "../../redux/byhorses/actions";
import { Col, Image, Row } from 'antd';
import horse from "../../assets/images/byhorse/At.png";
import studFarm from "../../assets/images/byhorse/Hara.png";
import jockey from "../../assets/images/byhorse/Jokey.png";
import user from "../../assets/images/byhorse/manAndWomen.png";
import axios from 'axios';
import { getHorsesCount, getUsersCount } from './api';

const DashboardContent = (props) => {
  const [horseCount, setHorseCount] = useState(0)
  const [studFarmCount, setStudFarmCount] = useState(0)
  const [jockeyCount, setJockeyCount] = useState(0)
  const [userCount, setUserCount] = useState(0)

  const handleGetHorsesCount = async ({ source }) => {
    try {
      const response = await getHorsesCount({ cancelToken: source.token })
      setHorseCount(response.data.count)
    } catch (error) {
      console.log('ERROR: ', error)
    }
  }
  const handleGetStudFarmCount = async ({ source }) => {
    try {
      setStudFarmCount(0)
    } catch (error) {

    }
  }
  const handleGetJockeyCount = async ({ source }) => {
    try {
      setJockeyCount(0)
    } catch (error) {

    }
  }
  const handleGetUsersCount = async ({ source }) => {
    try {
      const response = await getUsersCount({ cancelToken: source.token })
      setUserCount(response.data.count)
    } catch (error) {
      console.log('ERROR: ', error)
    }
  }

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source()
    handleGetHorsesCount({ source })
    handleGetStudFarmCount({ source })
    handleGetJockeyCount({ source })
    handleGetUsersCount({ source })

    return () => {
      source.cancel()
    }
  }, [])


  const style = { border: "2px solid #772382", borderRadius: 10 }

  return (
    <div>
      <Row>
        <Col xs={24} className='bg-white p-20'>
          <Row gutter={[24, 24]}>
            <Col xs={24} align='center'>
              <span className='page-title-style'>Sayı bilgileri</span>
            </Col>
            <Col xs={24} lg={6} align='center'>
              <div className='p-10' style={style}>
                <Image preview={false} src={horse} alt="At" />
                <span className='page-title-style'>{horseCount}</span>
              </div>
            </Col>
            <Col xs={24} lg={6} align='center'>
              <div className='p-10' style={style}>
                <Image preview={false} src={studFarm} />
                <span className='page-title-style'>{studFarmCount}</span>
              </div>
            </Col>
            <Col xs={24} lg={6} align='center'>
              <div className='p-10' style={style}>
                <Image preview={false} src={jockey} />
                <span className='page-title-style'>{jockeyCount}</span>
              </div>
            </Col>
            <Col xs={24} lg={6} align='center'>
              <div className='p-10' style={style}>
                <Image preview={false} src={user} />
                <span className='page-title-style'>{userCount}</span>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}


const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(DashboardContent));