import { protectedApi } from '../../services/axios'


export const getHorses = async (params) => {
  let { cancelToken, offset, limit, ordering, idSearch, imageFilter, nameSearch,
    birthDateSearch, sexFilter, colourFilter, breedFilter, ownerSearch,
    breederSearch, fatherNameSearch, motherNameSearch, statusFilter } = params
  let filters = ""

  if (limit) {
    filters += `limit=${limit}&`
  }
  if (offset) {
    filters += `offset=${(offset - 1) * limit}&`
  }
  if (ordering) {
    filters += `ordering=${ordering}&`
  }
  if (idSearch) {
    filters += `id=${idSearch}&`
  }
  if (imageFilter !== "") {
    if (imageFilter === 0) {
      filters += `images__len=0&`
    } else if (imageFilter === 1) {
      filters += `images__len__gt=1&`
    }
  }
  if (nameSearch) {
    filters += `name__icontains=${nameSearch}&`
  }
  if (birthDateSearch) {
    filters += `birthDate=${birthDateSearch}&`
  }
  if (sexFilter) {
    filters += `sex__in=${sexFilter}&`
  }
  if (colourFilter) {
    filters += `colour__in=${colourFilter}&`
  }
  if (breedFilter) {
    filters += `breed__in=${breedFilter}&`
  }
  if (ownerSearch) {
    filters += `horseOwnersWithPercentage__icontains=${ownerSearch}&`
  }
  if (breederSearch) {
    filters += `breeder__fullName__icontains=${breederSearch}&`
  }
  if (fatherNameSearch) {
    filters += `father__name__icontains=${fatherNameSearch}&`
  }
  if (motherNameSearch) {
    filters += `mother__name__icontains=${motherNameSearch}&`
  }
  if (statusFilter) {
    filters += `status__in=${statusFilter}&`
  }
  try {
    const response = await protectedApi.get(`management/horses?${filters}`, { cancelToken: cancelToken });
    return response;
  } catch (error) {
    return error.response;
  }
}
export const getHorse = async (id) => {
  try {
    const response = await protectedApi.get(`management/horse/${id}`);
    return response;
  } catch (error) {
    return error.response;
  }
}


export const createHorse = async (payload) => {
  try {
    const response = await protectedApi.post('management/horses', payload);
    return response;
  } catch (error) {
    return error.response;
  }
}
export const updateHorse = async (id, payload) => {
  try {
    const response = await protectedApi.put(`management/horse/${id}`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
}
export const deleteHorse = async (id, payload) => {
  try {
    const response = await protectedApi.put(`management/horse/${id}`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const createHorseParent = async (payload) => {
  try {
    const response = await protectedApi.post('management/horseparents', payload);
    return response;
  } catch (error) {
    return error.response;
  }
}
export const updateHorseParent = async (id, payload) => {
  try {
    const response = await protectedApi.put(`management/horseparent/${id}`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const uploadFiles = async (payload) => {
  try {
    const response = await protectedApi.post(`management/images`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const getPersons = async ({ cancelToken }) => {
  try {
    const response = await protectedApi.get('management/horsepeople', { cancelToken: cancelToken });
    return response;
  } catch (error) {
    return error.response;
  }
}
export const createHorsePerson = async (payload) => {
  try {
    const response = await protectedApi.post('management/horsepeople', payload);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const getHorseParentsByHorseId = async (horse_id) => {
  try {
    const response = await protectedApi.get(`management/horseparents?horse__id=${horse_id}`);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const createHorseOwner = async (payload) => {
  try {
    const response = await protectedApi.post('management/horseowners', payload);
    return response;
  } catch (error) {
    return error.response;
  }
}
export const deleteHorseOwner = async (id) => {
  try {
    const response = await protectedApi.put(`management/horseowner/${id}`, { isDeleted: true });
    return response;
  } catch (error) {
    return error.response;
  }
}


export const createHorseFamily = async (payload) => {
  try {
    const response = await protectedApi.post('management/horsefamilies', payload);
    return response;
  } catch (error) {
    return error.response;
  }
}
export const getHorseFamily = async ({ cancelToken, searchParam }) => {
  try {
    const response = await protectedApi.get(`management/horsefamilies?name__istartswith=${searchParam}`, { cancelToken: cancelToken });
    return response;
  } catch (error) {
    return error.response;
  }
}


export const checkHorseUniq = async (horseName) => {
  try {
    const response = await protectedApi.post('management/checkhorsename', { horseName: horseName });
    return response;
  } catch (error) {
    return error.response;
  }
}