import { Button, Col, Row } from 'antd'
import React, { useState } from 'react'
import Login from './Login';
import Register from './Register';


const activeBtnStyle = {
  borderRadius: 0,
  border: 0,
  borderTopRightRadius: 8,
  borderTopLeftRadius: 8,
  paddingTop: 16,
  paddingBottom: 46,
  fontSize: 18,
  backgroundColor: '#FFFFFF',
  color: '#472682',
}
const passiveBtnStyle = {
  borderRadius: 0,
  border: 0,
  borderTopRightRadius: 8,
  borderTopLeftRadius: 8,
  paddingTop: 16,
  paddingBottom: 46,
  fontSize: 18,
  color: '#FFFFFF',
  backgroundColor: '#472682',
}

const LoginContent = () => {
  const [activeTab, setActiveTab] = useState(1)

  const setTab = (value) => {
    setActiveTab(value)
  }
  return (
    <div>
      <Row className='my-20'>
        <Col xs={{span:22, offset:1}} md={{ span: 8, offset: 8 }}>
          <Row gutter={[0, 24]} className='login-card'>
            <Col xs={24}>
              <Button onClick={() => setTab(1)} size='large' style={activeTab === 1 ? activeBtnStyle : passiveBtnStyle} block={true}>Giriş Yap</Button>
            </Col>
            {/* <Col xs={12}>
              <Button onClick={() => setTab(2)} size='large' style={activeTab === 2 ? activeBtnStyle : passiveBtnStyle} block={true}>Kayıt Ol</Button>
            </Col> */}
            <Col xs={24} >
              {activeTab === 1 ?
                <Login setTab={setTab} /> : <Register setTab={setTab} />
              }
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default LoginContent