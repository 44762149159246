import React, { useEffect, useState } from 'react'
import { useHistory, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRedux } from "../../redux/byhorses/actions";
import { Button, Col, Modal, Pagination, Row, Space, Table } from 'antd';
import axios from 'axios';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { showNotification } from '../../utils/showNotification';
import moment from 'moment';
import { deleteJockey, getJockeys } from './api';

import tableExport from "antd-table-export";

const { confirm } = Modal;
const JockeysContent = (props) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([]);
  const [paginationInfo, setPaginationInfo] = useState({ offset: 1, limit: 10 })
  const [orderCount, setOrderCount] = useState(null)


  const history = useHistory()


  const handleGetJockeys = async ({ source, offset, limit }) => {
    setLoading(true)
    try {
      const response = await getJockeys({ cancelToken: source.token, offset: offset, limit: limit })
      setOrderCount(response.data.count)
      const tempArr = response.data.results.map((data, index) => {
        return {
          key: index,
          id: data.id,
          firstname: data.firstName,
          lastname: data.lastName,
          nickname: data.nickName,
          birthPlace: data.birthPlace,
          birthDate: moment(data.birthDate).format('DD.MM.YYYY'),
          height: data.height,
          weight: data.weight,
          jockeyType: data.jockeyType === 1 ? "Gerçek" : "Dijital",
          status: data.status === 1 ? "New" : data.status === 2 ? "Model" : data.status === 3 ? "Control" : data.status === 4 ? "NFT" : data.status === 5 ? "Published" : "",
        }
      })
      setData(tempArr)
    } catch (error) {
      console.log('ERROR: ', error)
    }
    setLoading(false)
  }

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source()
    handleGetJockeys({ offset: paginationInfo.offset, limit: paginationInfo.limit, source })

    return () => {
      source.cancel()
    }
  }, [paginationInfo])


  const handleDeleteJockey = async (info) => {

    const payload = {
      isDeleted: true,
      nickName: info.nickname
    }

    confirm({
      content: "Seçtiğiniz jokeyi silmek istediğinize emin misiniz?",
      okText: "Evet",
      cancelText: "Hayır",
      async onOk() {
        props.setRedux({ loading: true })
        const response = await deleteJockey(info.id, payload)
        if (response.status === 200) {
          showNotification({ type: 'success', title: 'Silindi!', description: 'Seçmiş olduğunuz jokey silindi!' })
          setPaginationInfo({ offset: 1, limit: 10 })
        } else {
          showNotification({ type: 'error', title: 'Hata!', description: 'Silinemedi!' })
        }
        props.setRedux({ loading: false })
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }


  const handleTablePagination = (offset, limit) => {
    // Bütün veriyi direkt çekmekten yerine her tabloda gösterilen kadar veri çekmek için
    // tablo pagination'ın onchange fonksiyonunu kullanacağız.
    setPaginationInfo(prevState => ({
      ...prevState,
      offset: offset,
      limit: limit
    }))
  }

  const handleExportExcel = () => {
    const exportInstance = new tableExport(data, columns.filter((dt) => dt.key !== "action"));
    exportInstance.download("jockeys", "xlsx");
  }

  const columns = [
    {
      title: 'Adı',
      dataIndex: 'firstname',
      key: 'firstname',
    },
    {
      title: 'Soyad',
      dataIndex: 'lastname',
      key: 'lastname',
    },
    {
      title: 'Lakap',
      dataIndex: 'nickname',
      key: 'nickname',
    },
    {
      title: 'Doğum Yeri',
      dataIndex: 'birthPlace',
      key: 'birthPlace',
    },
    {
      title: 'Doğum Tarihi',
      dataIndex: 'birthDate',
      key: 'birthDate',
    },
    {
      title: 'Boy',
      dataIndex: 'height',
      key: 'height',
    },
    {
      title: 'Kilo',
      dataIndex: 'weight',
      key: 'weight',
    },
    {
      title: 'Tipi',
      dataIndex: 'jockeyType',
      key: 'jockeyType',
    },
    {
      title: 'Statü',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      filters: [
        {
          text: 'New',
          value: 'New',
        },
        {
          text: 'Model',
          value: 'Model',
        },
        {
          text: 'Control',
          value: 'Control',
        },
        {
          text: 'NFT',
          value: 'NFT',
        },
        {
          text: 'Ready',
          value: 'Ready',
        },
        {
          text: 'Published',
          value: 'Published',
        },
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
    },
    {
      title: 'İşlem',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <EditOutlined className='cursor-p' onClick={() => history.push(`update-jockey/${record.id}`)} />
          <DeleteOutlined className='cursor-p' onClick={() => handleDeleteJockey(record)} />
        </Space>
      ),
    },
  ];
  return (
    <div>
      <Row>
        <Col xs={24} className='bg-white p-20'>
          <Row gutter={[0, 24]}>
            <Col xs={12} align='left'>
              <span className='page-title-style'>Jokeyler</span>
            </Col>
            <Col xs={12} align='right'>
              <Space>
                <Button onClick={handleExportExcel}>Export Excel</Button>
                <Button type='primary' onClick={() => history.push('/create-jockey')}>Ekle</Button>
              </Space>
            </Col>
            <Col xs={24}>
              <Table columns={columns} dataSource={data} size='small' className='small-table' loading={loading} pagination={false} />
            </Col>
            <Col xs={24} align='right'>
              <Pagination size='small' style={{ fontSize: 12 }} onChange={handleTablePagination} current={paginationInfo.offset} defaultCurrent={1} total={orderCount} showTotal={(total) => `Toplam: ${total}`} />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(JockeysContent));