import React, { useEffect, useRef, useState } from 'react'
import { useHistory, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRedux } from "../../redux/byhorses/actions";
import { Button, Col, Image, Input, Modal, Popover, Row, Space, Table } from 'antd';
import axios from 'axios';
import { deleteHorse, getHorses } from './api';
import moment from 'moment';
import { AppstoreAddOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined, EyeOutlined, FireOutlined, SearchOutlined } from '@ant-design/icons';
import { showNotification } from '../../utils/showNotification';
import { breed, sex, skinColourOpt } from './utils';
import { calcAge } from '../../utils/CalcAge';

import tableExport from "antd-table-export";
import mainService from '../../contracts/services/main.service';
// import { getRPCErrorMessage } from '../../contracts/utils/ErrorHandler';
import { txUrl } from "../../contracts/utils/Constants"

const { confirm } = Modal;


const sexFilters = sex.map((val, index) => {
  return {
    text: val,
    value: index + 1,
  }
})

const colourFilters = skinColourOpt.map((val, index) => {
  return {
    text: val.name,
    value: val.id,
  }
})
const breedFilters = breed.map((val, index) => {
  return {
    text: val,
    value: index + 1,
  }
})


const HorsesContent = (props) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([]);
  const [imageModalVisible, setImageModalVisible] = useState(false);
  const [row, setRow] = useState({});
  const [paginationInfo, setPaginationInfo] = useState({ offset: 1, limit: 10 })
  const [dataCount, setDataCount] = useState(0)
  const [ordering, setOrdering] = useState("")
  const [idFilter, setIdFilter] = useState("")
  const [imageFilter, setImageFilter] = useState("")
  const [nameFilter, setNameFilter] = useState("")
  const [birthDateFilter, setBirthDateFilter] = useState("")
  const [sexFilter, setSexFilter] = useState("")
  const [colourFilter, setColourFilter] = useState("")
  const [breedFilter, setBreedFilter] = useState("")
  const [ownerFilter, setOwnerFilter] = useState("")
  const [breederFilter, setBreederFilter] = useState("")
  const [fatherNameFilter, setFatherNameFilter] = useState("")
  const [motherNameFilter, setMotherNameFilter] = useState("")
  const [statusFilter, setStatusFilter] = useState("")



  const history = useHistory()

  const searchInput = useRef(null);

  const openImageModal = () => setImageModalVisible(true)
  const closeImageModal = () => setImageModalVisible(false)


  const createAndMintNFT = async (record) => {
    const isOwner = await mainService.isOwner({ account: props.reduxState.metamaskAccount })
    if (isOwner === false) {
      showNotification({
        type: 'info',
        title: 'REDDEDİLDİ!.',
        description: "Kontrat sahibi değilsiniz"
      })
      return
    }

    const createHorse = {
      id: record.id,
      uri: "ipfs/QmWac3BJwcP8ers2eAkjJfyd4PTWoVPothoaETQwWmojRP/0000000000000000000000000000000000000000000000000000000000000001.json",
      totalSupply: 1,
      sender: props.reduxState.metamaskAccount
    }
    try {
      const createHorseTx = await mainService.createHorses(createHorse)
      showNotification({
        type: 'info',
        title: 'At oluşturma işlemi için transaction başlatıldı. Süreci alttaki link aracılığıyla görebilirsiniz.',
        description: <a href={txUrl + createHorseTx}>Transaction'ı görmek için tıklayın!</a>
      })


      // let w3 = new Web3(window.ethereum);
      // let transactionStatus = null;
      // let refreshIntervalId = setInterval(async () => {
      //   const transactionStatus = await w3.eth.getTransactionReceipt(response)
      //   console.log('INTERVAL IÇI : ', transactionStatus)
      // }, 5000);
      // if(transactionStatus !== null) {
      //   console.log('TRANSACTION STATUS: ', transactionStatus)
      //   clearInterval(refreshIntervalId)
      // }

      // AFTER THE TRANSACTION STATUS !== FALSE
      const mintHorse = {
        account: props.reduxState.metamaskAccount,
        id: record.id,
        amount: 1,
        sender: props.reduxState.metamaskAccount
      }

      const mintHorseTx = await mainService.mint(mintHorse)
      showNotification({
        type: 'info',
        title: 'Mint işlemi için transaction başlatıldı. Süreci alttaki link aracılığıyla görebilirsiniz.',
        description: <a href={txUrl + mintHorseTx}>Transaction'ı görmek için tıklayın!</a>
      })


    } catch (error) {
      console.log('CREATE TYPE ERROR: ', error)
      showNotification({
        type: 'error',
        title: 'Something Went Wrong!',
        description: error.message
      })
    }
  }

  const handleCreateNFT = (record) => {
    confirm({
      title: 'NFT üretmek istediğinize emin misiniz?',
      icon: <ExclamationCircleOutlined />,
      content: 'Seçili at için NFT üretimi başlayacak, lütfen gerekli at bilgilerini doldurduğunuzu onaylayın',
      onOk() {
        createAndMintNFT(record)
      },
      onCancel() { },
    });
  }

  const burnNFT = async (record) => {

    const burnHorse = {
      from: props.reduxState.metamaskAccount,
      id: record.id,
      amount: 1,
    }
    try {
      const burnHorseTx = await mainService.burn(burnHorse)
      showNotification({
        type: 'info',
        title: 'Mint işlemi için transaction başlatıldı. Süreci alttaki link aracılığıyla görebilirsiniz.',
        description: <a href={txUrl + burnHorseTx}>Transaction'ı görmek için tıklayın!</a>
      })
    } catch (error) {
      console.log('CREATE TYPE ERROR: ', error)
      showNotification({
        type: 'error',
        title: 'Something Went Wrong!',
        description: error.message
      })
    }
  }


  const handleBurnNFT = async (record) => {
    const isOwner = await mainService.isOwner({ account: props.reduxState.metamaskAccount })
    if (isOwner === false) {
      showNotification({
        type: 'info',
        title: 'REDDEDİLDİ!.',
        description: "Kontrat sahibi değilsiniz"
      })
      return
    }
    confirm({
      title: 'NFT silmek istediğinize emin misiniz?',
      icon: <ExclamationCircleOutlined />,
      content: 'Seçili at için NFT yakma işlemi başlayacak, bu işlem geri alınamaz!',
      onOk() {
        burnNFT(record)
      },
      onCancel() { },
    });
  }



  const handleShowImages = (record) => {
    setRow(record)
    openImageModal()
  }

  const handleGetHorses = async (params) => {
    let { source, offset, limit, ordering, idSearch, imageFilter,
      nameSearch, birthDateSearch, sexFilter,
      colourFilter, breedFilter, ownerSearch,
      breederSearch, fatherNameSearch, motherNameSearch, statusFilter } = params
    setLoading(true)
    try {
      const response = await getHorses({
        cancelToken: source.token,
        offset: offset,
        limit: limit, ordering: ordering,
        idSearch: idSearch,
        imageFilter: imageFilter,
        nameSearch: nameSearch,
        birthDateSearch: birthDateSearch,
        sexFilter: sexFilter,
        colourFilter: colourFilter,
        breedFilter: breedFilter,
        statusFilter: statusFilter,
        ownerSearch: ownerSearch,
        breederSearch: breederSearch,
        fatherNameSearch: fatherNameSearch,
        motherNameSearch: motherNameSearch,
      })
      setDataCount(response.data.count)
      const tempArr = response.data.results.map((data, index) => {
        let tempOwner = ""
        let owner;
        data.horseOwnersWithPercentage.map((dt, index) => {
          const newOwner = `${dt.horsePerson__fullName} %${dt.ownerPercentage}${(data.horseOwnersWithPercentage.length > 1 && data.horseOwnersWithPercentage.length - 1 !== index) ? "\n" : ""}`
          owner = tempOwner.concat(newOwner)
          tempOwner = owner
          return null
        })
        let tempBreeder = ""
        let breeder;
        data.breeder.map(dt => {
          const newBreeder = `${dt.fullName}${(data.breeder.length > 1 && data.breeder.length - 1 !== index) ? "\n" : ""}`
          breeder = tempBreeder.concat(newBreeder)
          tempBreeder = breeder
          return null
        })
        return {
          key: index,
          id: data.id,
          name: data.name,
          birthDate: data.birthDate ? moment(data.birthDate).format('DD.MM.YYYY') : null,
          age: data.birthDate ? calcAge(moment(data.birthDate).format('YYYY-MM-DD')) : null,
          ageInt: data.birthDate ? moment().diff(moment(data.birthDate).format('YYYY-MM-DD'), 'years') : null,
          sex: sex[data.sex - 1],
          colour: skinColourOpt.filter(dt => dt.id === data.colour)[0]?.name,
          breed: breed[data.breed - 1],
          horseOwner: owner || "",
          breeder: breeder || "",
          fatherName: data.father?.name,
          motherName: data.mother?.name,
          images: data.images,
          status: data.status === 1 ? "New" : data.status === 2 ? "Model" : data.status === 3 ? "Control" : data.status === 4 ? "NFT" : data.status === 5 ? "Published" : ""
        }
      })
      setData(tempArr)
    } catch (error) {
      console.log('ERROR: ', error)
    }
    setLoading(false)
  }

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source()
    handleGetHorses({
      source: source,
      offset: paginationInfo.offset,
      limit: paginationInfo.limit,
      ordering: ordering,
      idSearch: idFilter,
      imageFilter: imageFilter,
      nameSearch: nameFilter,
      birthDateSearch: birthDateFilter,
      sexFilter: sexFilter,
      colourFilter: colourFilter,
      breedFilter: breedFilter,
      statusFilter: statusFilter,
      ownerSearch: ownerFilter,
      breederSearch: breederFilter,
      fatherNameSearch: fatherNameFilter,
      motherNameSearch: motherNameFilter,
    })

    return () => {
      source.cancel()
    }
  }, [paginationInfo, ordering, idFilter, imageFilter, nameFilter, birthDateFilter, sexFilter, colourFilter, breedFilter, statusFilter, ownerFilter, breederFilter, fatherNameFilter, motherNameFilter])




  const handleTableChange = (pagination, filters, sorter) => {
    // console.log('filters: ', filters)

    setPaginationInfo(prevState => ({
      ...prevState,
      offset: pagination.current,
      limit: pagination.pageSize
    }))

    if (sorter.field === "image") {
      sorter.field = "images"
    }
    if (sorter.field === "age") {
      sorter.field = "birthDate"
    }
    if (sorter.field === "fatherName") {
      sorter.field = "father__name"
    }
    if (sorter.field === "motherName") {
      sorter.field = "mother__name"
    }
    if (sorter.order) {
      const order_ = sorter.order === "ascend" ? sorter.field : `-${sorter.field}`
      setOrdering(order_)
    } else {
      setOrdering("")
    }

    console.log('FILTERS: ', filters)
    if (filters.id) {
      setIdFilter(filters.id[0])
    } else {
      setIdFilter("")
    }
    if (filters.image) {
      if (filters.image.length === 2) {
        setImageFilter("")
      } else {
        setImageFilter(filters.image[0])
      }
    }
    if (filters.name) {
      setNameFilter(filters.name[0])
    } else {
      setNameFilter("")
    }
    if (filters.birthDate) {
      setBirthDateFilter(filters.birthDate[0])
    } else {
      setBirthDateFilter("")
    }
    if (filters.sex) {
      const str = filters.sex.join(",")
      setSexFilter(str)
    } else {
      setSexFilter("")
    }
    if (filters.colour) {
      const str = filters.colour.join(",")
      setColourFilter(str)
    } else {
      setColourFilter("")
    }
    if (filters.breed) {
      const str = filters.breed.join(",")
      setBreedFilter(str)
    } else {
      setBreedFilter("")
    }
    if (filters.horseOwner) {
      setOwnerFilter(filters.horseOwner[0])
    } else {
      setOwnerFilter("")
    }
    if (filters.breeder) {
      setBreederFilter(filters.breeder[0])
    } else {
      setBreederFilter("")
    }
    if (filters.fatherName) {
      setFatherNameFilter(filters.fatherName[0])
    } else {
      setFatherNameFilter("")
    }
    if (filters.motherName) {
      setMotherNameFilter(filters.motherName[0])
    } else {
      setMotherNameFilter("")
    }
    if (filters.status) {
      const str = filters.status.join(",")
      setStatusFilter(str)
    } else {
      setStatusFilter("")
    }


  }


  const handleDeleteHorse = async (info) => {
    const payload = {
      name: info.name,
      isDeleted: true
    }
    confirm({
      content: "Seçtiğiniz atı silmek istediğinize emin misiniz?",
      okText: "Evet",
      cancelText: "Hayır",
      async onOk() {
        props.setRedux({ loading: true })
        const response = await deleteHorse(info.id, payload)
        if (response.status === 200) {
          setPaginationInfo({ offset: 1, limit: 10 })
          showNotification({ type: 'success', title: 'Silindi!', description: 'Seçmiş olduğunuz at silindi!' })
        } else {
          showNotification({ type: 'error', title: 'Hata!', description: 'Silinemedi!' })

        }
        props.setRedux({ loading: false })
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    // setSearchText(selectedKeys[0]);
    // setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();

  };
  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`${title} ile ara`}
          value={selectedKeys[0]}
          type={dataIndex === "birthDate" ? "date" : undefined}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}>
            Ara
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}>
            Temizle
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    // onFilter: (value, record) => {
    //   if (dataIndex === "birthDate") { value = moment(value).format("DD.MM.YYYY") }
    //   if ((dataIndex === "fatherName" || dataIndex === "motherName") && record[dataIndex] === null) return
    //   if (dataIndex === "breeder") {
    //     return record[dataIndex].map((dt, i) => dt.fullName.toString().toLocaleLowerCase().includes(value.toLocaleLowerCase()))[0]
    //   }
    //   if (dataIndex === "horseOwner") {
    //     return record[dataIndex].map((dt, i) => dt.horsePerson__fullName.toString().toLocaleLowerCase().includes(value.toLocaleLowerCase()))[0]
    //   }
    //   return record[dataIndex].toString().toLocaleLowerCase().includes(value.toLocaleLowerCase())
    // },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => text,
  });

  const handleExportExcel = () => {
    const exportInstance = new tableExport(data, columns.filter((dt) => dt.key !== "action"));
    exportInstance.download("horses", "xlsx");
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
      ...getColumnSearchProps('id', "ID")
    },
    {
      title: 'Görsel',
      dataIndex: 'image',
      key: 'image',
      align: 'center',
      render: (text, record) => {
        if (record.images.length === 0) {
          return <></>
        }
        return (
          <EyeOutlined onClick={() => handleShowImages(record)} style={{ fontSize: 16 }} />
        )
      },
      sorter: true,
      filters: [
        {
          text: 'Görseli Olan',
          value: 1,
        },
        {
          text: 'Görseli Olmayan',
          value: 0,
        },
      ],
      // onFilter: (value, record) => value === 1 ? record.images.length > 0 : record.images.length === 0
    },
    {
      title: 'Adı',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      ...getColumnSearchProps('name', "Adı")
    },
    {
      title: 'Doğ.Tar.',
      dataIndex: 'birthDate',
      key: 'birthDate',
      sorter: true,
      ...getColumnSearchProps('birthDate', "Doğum Tarihi")
    },
    {
      title: 'Yaş',
      dataIndex: 'age',
      key: 'age',
      sorter: true,
    },
    {
      title: 'Cinsiyet',
      dataIndex: 'sex',
      key: 'sex',
      sorter: true,
      filters: sexFilters,
      // onFilter: (value, record) => value === record.sex,
    },
    {
      title: 'Donu',
      dataIndex: 'colour',
      key: 'colour',
      sorter: true,
      filters: colourFilters,
      // onFilter: (value, record) => value === record.colour,
    },
    {
      title: 'Irkı',
      dataIndex: 'breed',
      key: 'breed',
      sorter: true,
      filters: breedFilters,
      // onFilter: (value, record) => value === record.breed,
    },
    {
      title: 'Sahibi',
      dataIndex: 'horseOwner',
      key: 'horseOwner',
      ...getColumnSearchProps('horseOwner', "Sahibi"),
    },
    {
      title: 'Yetiştirici',
      dataIndex: 'breeder',
      key: 'breeder',
      ...getColumnSearchProps('breeder', "Yetiştirici"),
    },
    {
      title: 'B. Adı',
      dataIndex: 'fatherName',
      key: 'fatherName',
      sorter: true,
      ...getColumnSearchProps('fatherName', "Baba Adı")
    },
    {
      title: 'A. Adı',
      dataIndex: 'motherName',
      key: 'motherName',
      sorter: true,
      ...getColumnSearchProps('motherName', "Anne Adı")
    },
    {
      title: 'Statü',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      filters: [
        {
          text: 'New',
          value: 1,
        },
        {
          text: 'Model',
          value: 2,
        },
        {
          text: 'Control',
          value: 3,
        },
        {
          text: 'NFT',
          value: 4,
        },
        {
          text: 'Ready',
          value: 5,
        },
        {
          text: 'Published',
          value: 6,
        },
      ],
      // onFilter: (value, record) => record.status.indexOf(value) === 0,
    },
    {
      title: 'İşlem',
      key: 'action',
      render: (text, record) => (
        <Row gutter={[8, 8]}>
          <Col xs={12}>
            <Popover content={<div>NFT Üret</div>} title="">
              <AppstoreAddOutlined className='cursor-p' onClick={() => handleCreateNFT(record)} />
            </Popover>
          </Col>
          <Col xs={12}>
            <Popover placement="left" content={<div style={{whiteSpace: "nowrap"}}>Atı Güncelle</div>} title="">
            <a href={`update-horse/${record.id}`} target={"_blank"} rel="noreferrer"><EditOutlined /></a>
            </Popover>
          </Col>
          <Col xs={12}>
            <Popover content={<div>NFT Sil</div>} title="">
              <FireOutlined className='cursor-p' onClick={() => handleBurnNFT(record)} />
            </Popover>
          </Col>
          <Col xs={12}>
            <Popover content={<div>Atı Sil</div>} title="">
              <DeleteOutlined className='cursor-p' onClick={() => handleDeleteHorse(record)} />
            </Popover>
          </Col>
        </Row>
      ),
    },
  ];
  return (
    <div>
      <Row>
        <Col xs={24} className='bg-white p-20'>
          <Row gutter={[0, 24]}>
            <Col xs={12} align='left'>
              <span className='page-title-style'>Atlar</span>
            </Col>
            <Col xs={12} align='right'>
              <Space>
                <Button onClick={handleExportExcel}>Export Excel</Button>
                <Button type='primary' onClick={() => history.push('/create-horse')}>Ekle</Button>
              </Space>
            </Col>
            <Col xs={24}>
              <Table columns={columns} dataSource={data} onChange={handleTableChange}
                size='small' className='small-table' loading={loading} scroll={{ x: 700 }}
                pagination={{ total: dataCount, showTotal: (total) => `Toplam: ${total}`, showQuickJumper: true }} />
            </Col>
            <Col xs={24} align='right'>
              {/* <Pagination size='small' style={{ fontSize: 12 }} onChange={handleTablePagination} current={paginationInfo.offset} defaultCurrent={1} total={dataCount} showTotal={(total) => `Toplam: ${total}`} /> */}
            </Col>
          </Row>
        </Col>
      </Row>

      <Modal width={"70%"} className="top-10-modal" visible={imageModalVisible} title={''} footer={null} onCancel={closeImageModal}>
        <Row gutter={[24, 24]}>
          {row.images?.map((url, index) => (
            <Col key={index} xs={24} lg={12}>
              <Image src={url} />
            </Col>
          ))}
        </Row>
      </Modal>
    </div>
  )
}

const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(HorsesContent));