import React, { useEffect } from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRedux } from "../../redux/byhorses/actions";
import { Button, Col, Form, Input, Modal, Row, Space, Table } from 'antd';
import { useState } from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { createDictionary, deleteDictionary, getDictionaries, updateDictionary } from './api';
import axios from 'axios';
import { showNotification } from '../../utils/showNotification';

const { confirm } = Modal;


const alpha = Array.from(Array(26)).map((e, i) => i + 65);
const alphabet = alpha.map((x) => String.fromCharCode(x));

const alphabetFilter = alphabet.map((val, index) => {
  return {
    text: val,
    value: val,
  }
})

const DictionaryContent = (props) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([]);
  const [addWordModalVisible, setAddWordModalVisible] = useState(false)
  const [updateData, setUpdateData] = useState({})

  const [form] = Form.useForm()

  const handleEditWord = (record) => {
    setUpdateData(record)
    setAddWordModalVisible(true)
    form.setFieldsValue({
      dictKeyword: record.keyword,
      description: record.description
    })
  }
  const handleDelete = (record) => {
    confirm({
      content: "Seçtiğiniz sözcüğü silmek istediğinize emin misiniz?",
      okText: "Evet",
      cancelText: "Hayır",
      async onOk() {
        props.setRedux({ loading: true })
        const response = await deleteDictionary(record.id)
        if (response.status === 200) {
          showNotification({ type: 'success', title: 'Silindi!', description: 'Seçmiş olduğunuz sözcük silindi!' })
          getDictionaries_({ source: { token: null } })
        } else {
          showNotification({ type: 'error', title: 'Hata!', description: 'Silinemedi!' })
        }
        props.setRedux({ loading: false })
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const onFinish = async (values) => {
    if (updateData.id) { // UPDATE
      try {
        await updateDictionary(updateData.id, values)
        getDictionaries_({ source: { token: null } })
        setAddWordModalVisible(false)
        showNotification({ type: 'success', title: 'Güncellendi!', description: 'Girmiş olduğunuz kelime sözlükte güncellendi!' })
        form.resetFields()
      } catch (error) {
        console.log(error)
        showNotification({ type: 'error', title: 'Upsss!', description: 'Yanlış bir şeyler oldu lütfen tekrar deneyin!' })
      }
    } else { // CREATE
      try {
        await createDictionary(values)
        getDictionaries_({ source: { token: null } })
        setAddWordModalVisible(false)
        showNotification({ type: 'success', title: 'Eklendi!', description: 'Girmiş olduğunuz kelime sözlüğe eklendi!' })
        form.resetFields()
      } catch (error) {
        console.log(error)
        showNotification({ type: 'error', title: 'Upsss!', description: 'Yanlış bir şeyler oldu lütfen tekrar deneyin!' })
      }
    }
  }

  const getDictionaries_ = async ({ source }) => {
    setLoading(true)
    try {
      const response = await getDictionaries({ cancelToken: source.token })
      const tempArr = response.data.results.map((data, index) => {
        return {
          key: index,
          id: data.id,
          keyword: data.dictKeyword,
          description: data.description
        }
      })
      setData(tempArr)
    } catch (error) {
      console.log('ERROR: ', error)
    }
    setLoading(false)
  }

  useEffect(() => {
    setLoading(false)
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source()
    getDictionaries_({ source })
    return () => {
      source.cancel()
    }
  }, [])


  const columns = [
    {
      title: 'Sözcük',
      dataIndex: 'keyword',
      key: 'keyword',
      width: 150,
      filters: alphabetFilter,
      onFilter: (value, record) => record.keyword.startsWith(value), // HARF SEÇİLDİĞİNDE SERVİSE HARF ÖZELİNDE İSTEK ÇIKMALI
      sorter: (a, b) => a.keyword.localeCompare(b.keyword)
    },
    {
      title: 'Açıklama',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'İşlem',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <EditOutlined className='cursor-p' onClick={() => handleEditWord(record)} />
          <DeleteOutlined className='cursor-p' onClick={() => handleDelete(record)} />
        </Space>
      ),
      width: 50
    },
  ];
  return (
    <div>
      <Row>
        <Col xs={24} className='bg-white p-20'>
          <Row gutter={[24, 24]}>
            <Col xs={12}>
              <span className='page-title-style'>Sözlük</span>
            </Col>
            <Col xs={12} align='right'>
              <Button type='primary' onClick={() => setAddWordModalVisible(true)}>Ekle</Button>
            </Col>
            <Col xs={24}>
              <Table columns={columns} dataSource={data} size='small' loading={loading} />
            </Col>
          </Row>
        </Col>
      </Row>

      <Modal visible={addWordModalVisible} title={'Sözcük Ekle'} onOk={() => form.submit()}
        onCancel={() => setAddWordModalVisible(false)}>
        <Form
          name="dictionary-form"
          form={form}
          initialValues={{}}
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          layout='horizontal'
          labelAlign='left'
          labelCol={{ xs: 8 }}
          wrapperCol={{ xs: 16 }}
          autoComplete="off">
          <Row>
            <Col xs={{ span: 24, offset: 0 }}>
              <Form.Item label="Sözcük" name="dictKeyword"
                rules={[{ required: true, message: 'Lütfen sözcük girin!' },]}>
                <Input size='large' placeholder='' />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24, offset: 0 }}>
              <Form.Item label="Açıklama" name="description"
                rules={[{ required: true, message: 'Lütfen sözcük için açıklama girin!' },]}>
                <Input.TextArea size='large' placeholder='' rows={3} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  )
}


const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(DictionaryContent));