import React from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRedux } from "../../redux/byhorses/actions";
import { Button, Checkbox, Col, Form, Input, Row } from 'antd'
import { MailOutlined, UserOutlined } from '@ant-design/icons';
import { strongRegex } from '../../utils/Constants';
import { signUp } from './api';
import { showNotification } from '../../utils/showNotification';


const Register = (props) => {


  const onFinish = async (values) => {
    props.setRedux({ loginLoading: true })
    console.log('Success:', values);

    try {
      const response = await signUp(values)
      if (response.status === 201) {
        showNotification({ type: 'success', title: 'Kayıt olundu!', description: 'Kayıt oldunuz sizi giriş ekranına yönlendiriyoruz!' })
        props.setRedux({ loginLoading: false })
        props.setTab(1)
        return
      }
      if (response.data.email) {
        showNotification({ type: 'error', title: 'Zaten kayıtlı!', description: 'Bu e-posta adresi sitemiz üzerinde zaten kayıtlı!' })
      }
      if (response.data.non_field_errors) {
        showNotification({ type: 'error', title: 'Benzer Şifre!', description: 'E-posta adresiniz şifreniz ile çok benzer! Lütfen farklı bir şifre kullanın!' })
      }
    } catch (error) {
      console.log('error : ', error)
      showNotification({ type: 'error', title: 'Hmm!', description: 'Beklenmedik bir hata oluştu!' })
    }

    props.setRedux({ loginLoading: false })
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div>
      <Row className='my-10'>
        <Col xs={24} align='center'>
          <span className='title-style'>Hesap Oluştur</span>
        </Col>
      </Row>
      <Form
        name="basic"
        // labelCol={{ span: 8 }}
        // wrapperCol={{ span: 18, offset: 3 }}
        initialValues={{ remember: false }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off">
        <Row>
          <Col xs={{ span: 18, offset: 3 }}>
            <Form.Item label="" name="firstName"
              rules={[{ required: true, message: 'Lütfen ad giriniz!' }]}>
              <Input suffix={<UserOutlined />} size='large' placeholder='Ad' />
            </Form.Item>
          </Col>
          <Col xs={{ span: 18, offset: 3 }}>
            <Form.Item label="" name="lastName"
              rules={[{ required: true, message: 'Lütfen soyad giriniz!' }]}>
              <Input suffix={<UserOutlined />} size='large' placeholder='Soyad' />
            </Form.Item>
          </Col>
          <Col xs={{ span: 18, offset: 3 }}>
            <Form.Item label="" name="email"
              rules={[
                { required: true, message: 'Lütfen e-posta giriniz!' },
                {
                  type: 'email',
                  message: 'Lütfen geçerli bir e-posta giriniz!',
                }]}>
              <Input suffix={<MailOutlined />} size='large' placeholder='E-Posta' />
            </Form.Item>
          </Col>
          <Col xs={{ span: 18, offset: 3 }}>
            <Form.Item label="" name="password1"
              rules={[
                { required: true, message: 'Lütfen şifre giriniz!' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (value && strongRegex.test(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Şifre Min 8 karakter olmalı ve en az 1 küçük ve büyük harfe (A-Z), bir sayıya(0-9), bir sembol(!,+,% vb.) içermeli!'));
                  },
                }),]}>
              <Input.Password size='large' placeholder='Şifre' />
            </Form.Item>
          </Col>
          <Col xs={{ span: 18, offset: 3 }}>
            <Form.Item label="" name="password2"
              rules={[
                { required: true, message: 'lütfen şifreyi tekrar giriniz!' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password1') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Şifreler eşleşmiyor!'));
                  },
                }),]}>
              <Input.Password size='large' placeholder='Şifre' />
            </Form.Item>
          </Col>
          <Col xs={{ span: 18, offset: 3 }} align='left'>
            <Form.Item name="remember" valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value ? Promise.resolve() : Promise.reject(new Error('Şartları kabul etmelisiniz!')),
                },
              ]}>
              <Checkbox>Kişisel verilerin
                <a className='login-link' target='_self' href='/login' rel="noreferrer"> Aydınlatma Metni</a> kapsamında işlenmektedir.
                <a className='login-link' target='_self' href='/login' rel="noreferrer"> Kullanım Koşulları</a> ve
                <a className='login-link' target='_self' href='/login' rel="noreferrer"> Gizlilik Bildirimi</a> ve
                <a className='login-link' target='_self' href='/login' rel="noreferrer"> Çerez Politikası</a>'nı kabul ediyorum</Checkbox>
            </Form.Item>
          </Col>
          <Col xs={{ span: 18, offset: 3 }}>
            <Form.Item>
              <Button block size='large' type="primary" htmlType="submit">
                Hesap Oluştur
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Row className='my-20'>
          <Col xs={24} align='center'>
            <span>Zaten hesabınız var mı? <span className='login-link' onClick={() => props.setTab(1)}>Giriş!</span></span>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(Register));