import { protectedApi } from '../../services/axios'


export const getJockeys = async ({cancelToken, offset, limit}) => {
  try {
    const response = await protectedApi.get(`management/jockeys?limit=${limit}&offset=${(offset-1)* limit}&`, {cancelToken: cancelToken});
    return response;
  } catch (error) {
    return error.response;
  }
}

export const getJockey = async (id) => {
  try {
    const response = await protectedApi.get(`management/jockey/${id}`);
    return response;
  } catch (error) {
    return error.response;
  }
}
export const updateJockey = async (id, payload) => {
  try {
    const response = await protectedApi.put(`management/jockey/${id}`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
}
export const deleteJockey = async (id, payload) => {
  try {
    const response = await protectedApi.put(`management/jockey/${id}`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const createJockey = async (payload) => {
  try {
    const response = await protectedApi.post('management/jockeys', payload);
    return response;
  } catch (error) {
    return error.response;
  }
}


export const uploadFiles = async (payload) => {
  try {
    const response = await protectedApi.post(`management/images`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const checkHorseUniq = async (payload) => {
  try {
    const response = await protectedApi.post('management/checkjockeynames', payload);
    return response;
  } catch (error) {
    return error.response;
  }
}