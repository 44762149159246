import { ClockCircleOutlined } from "@ant-design/icons";
import { notification } from "antd";

export const comingSoon = () => {
  notification.open({
      message: "Yakında",
      description: "Bu özellik şuanda devredışı",
      icon: <ClockCircleOutlined style={{ color: '#472682' }} />,
      duration: 2
  });
};