
import React, { useEffect } from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRedux } from "../../redux/byhorses/actions";
import { Col, Image, Row, Spin } from 'antd'
import logo from '../../assets/images/byhorses-logo/logo.png'
import LoginContent from './LoginContent';
import { LoadingOutlined } from '@ant-design/icons';


const LoginLayout = (props) => {

  useEffect(() => {
    props.setRedux({ loginLoading: false })
    window.localStorage.removeItem('user')
  }, []) // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={props.reduxState?.loginLoading}>
      <Row className='login-header' align='middle'>
        <Col xs={10}>
          <Image width={50} preview={false} src={logo} />
        </Col>
        <Col xs={14} align='right'>
          <a className='link-style' target='' href='/login' rel="noreferrer">Destek</a>
        </Col>
      </Row>
      <LoginContent />
    </Spin>
  )
}

const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(LoginLayout));