import React, { useState, useEffect, useRef } from 'react'
import { Input, Select } from 'antd';


const HorseSearchInput = (props) => {
  const [selectedPerson, setSelectedPerson] = useState(props.defaultSelected);
  const [showTextInput, setShowTextInput] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [searchValue, setSearchValue] = useState("");


  const selectRef = useRef(null)


  const handleSearch = (value) => {
    setSearchValue(value)
    props.searchValue(value)
  }

  const handleOnChange = (value, event) => {
    if (event === undefined) {
      setSelectedPerson()
      return
    }
    setSelectedPerson({ id: event.value, fullName: event.children })
  }

  const handleOnBlur = (event) => {
    if (selectedPerson) {
      props.handleIsSelected(true)
      props.setValue(selectedPerson)
    } else {
      setInputValue(event.target.value)
      setShowTextInput(true)
      props.handleIsSelected(false)
      props.setValue(event.target.value)
    }
  }

  const handleInputClick = () => {
    setShowTextInput(false)
    selectRef.current.focus();
  }

  useEffect(() => {
    setSelectedPerson(props.defaultSelected)
  }, [props.defaultSelected])

  const options = props.data.map((d, i) => <Select.Option key={i} value={d.id}>{d.name}</Select.Option>);
  return (
    <>
      <Input style={{ display: !showTextInput && "none" }} size={props.size} placeholder={props.placeholder}
        value={inputValue} onClick={handleInputClick} />
      <Select
        ref={selectRef}
        size={props.size}
        placeholder={props.placeholder}
        allowClear
        showSearch
        showArrow={false}
        filterOption={(input, option) => option.children?.toLowerCase().includes(input.toLowerCase())}
        searchValue={searchValue}
        onSearch={handleSearch}
        value={selectedPerson?.id}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        style={{ width: '100%', display: showTextInput && "none" }}>
        {options}
      </Select>
    </>
  );
}

export default HorseSearchInput