import { Button, Col, Form, Image, Input, Row, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { strongRegex } from '../../utils/Constants';
import logo from '../../assets/images/byhorses-logo/logo.png'
import { useParams } from 'react-router-dom';
import { createPassword } from './api';
import { LoadingOutlined } from '@ant-design/icons';
import { showNotification } from '../../utils/showNotification';
import { useHistory } from 'react-router-dom';

// Şifre güncelleme maili içerisinden tıklanınca açılacak linkin sayfası
const CreatePassword = () => {
  const [loading, setLoading] = useState(false)
  const [param, setParam] = useState({})

  let params = useParams()
  const history = useHistory();

  useEffect(() => {
    let token = params.token
    setParam({
      token: token
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  

  const onFinish = async (values) => {
    setLoading(true)
    delete values.new_password2
    values.token = param.token

    try {
      const response = await createPassword(values)
      console.log('RESPONSE : ', response)
      if(response.status === 200){
        showNotification('success', 'Şifreniz oluşturuldu!', 'Sizi login sayfasına yönlendiriyoruz.')
        setTimeout(() => {
          history.push('/login')
        },1500)
      }
      if(response.status === 400){
        showNotification('error', 'Geçersiz Token!', 'Şifre değiştirme için verilen token geçersiz. Lütfen Şifre güncelleme adımlarını tekrar yapın!')
      }
    } catch (error) {
      console.log('error : ', error)
    }
    setLoading(false)
  }

  return (
    <div>
    <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={loading}>
      <Row className='my-20'>
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 8, offset: 8 }}>
          <Row gutter={[0, 16]} className='login-card change-password'>
            <Col xs={24} align='center'>
              <span className='title-style'>Şifre Oluştur</span>
            </Col>
            <Col xs={24} align='center'>
              <Image src={logo} preview={false} />
            </Col>
            <Col xs={24}>
              <Form
                name="basic"
                // labelCol={{ span: 8 }}
                // wrapperCol={{ span: 18, offset: 3 }}
                initialValues={{ remember: false }}
                onFinish={onFinish}
                autoComplete="off">
                <Row>
                  <Col xs={{ span: 18, offset: 3 }}>
                    <Form.Item label="" name="password"
                      rules={[
                        { required: true, message: 'Lütfen şifre giriniz!' },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (value && strongRegex.test(value)) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error('Şifre Min 8 karakter olmalı ve en az 1 küçük ve büyük harfe (A-Z), bir sayıya(0-9), bir sembol(!,+,% vb.) içermeli!'));
                          },
                        }),]}>
                      <Input.Password size='large' placeholder='Şifre' />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 18, offset: 3 }}>
                    <Form.Item label="" name="new_password2"
                      rules={[
                        { required: true, message: 'lütfen şifreyi tekrar giriniz!' },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error('Şifreler eşleşmiyor!'));
                          },
                        }),]}>
                      <Input.Password size='large' placeholder='Şifre' />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 18, offset: 3 }}>
                    <Form.Item>
                      <Button block size='large' type="primary" htmlType="submit">
                        Şifre Oluştur
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
      </Spin>
    </div>
  )
}

export default CreatePassword