/* eslint-disable no-use-before-define */
import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRedux } from "../../../redux/byhorses/actions";
import { Button, Checkbox, Col, Divider, Form, Input, Modal, Row, Select, Space, Table, Upload } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import byHorsesEskal from "../../../assets/images/byhorse/byHorsesEskal.svg"
import { checkHorseUniq, createHorse, createHorseFamily, createHorseOwner, deleteHorseOwner, getHorse, updateHorse, uploadFiles } from '../api';
import { getApiBaseClear } from '../../../services/config';
import { showNotification } from '../../../utils/showNotification';
import Sketch from '../../../components/reactSketch/Sketch';
import JoditEditor from 'jodit-react';
import { beforeUploadFiles, breed, customRequest, dataURLtoFile, getBase64, INPUT_SIZE, normFile, onChangeFiles, richTextConfig, sex, skinColourOpt } from "../utils"
import AddOwnerAndBreeder from './AddOwnerAndBreeder';
import FamilyInputs from './FamilyInputs';


const NewHorseContent = (props) => {
  const [isCreatePage, setIsCreatePage] = useState(true);     // state that change if we are in update page

  const [horseOwnerData, setHorseOwnerData] = useState([]);
  const [breederData, setBreederData] = useState([]);

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [descriptionContent, setDescriptionContent] = useState('')


  const [family, setFamily] = useState({})

  const [saleMethod, setSaleMethod] = useState()



  // Sketch-Canvas image
  const [horseDescImage, setHorseDescImage] = useState(byHorsesEskal);

  const [mainForm] = Form.useForm()

  const history = useHistory()
  const params = useParams()

  // jodit editor referance
  const editor = useRef(null)
  // Skect-canvas referance
  const canvasDraw = useRef(null)

  // sketch-canvas clean function
  const clearCanvas = () => {
    canvasDraw.current.resetCanvas();
    setHorseDescImage(byHorsesEskal)
  }

  const checkForUniq = async () => {
    const name = mainForm.getFieldValue("name")

    try {
      const check = await checkHorseUniq(name)
      return check.data.status
    } catch (error) {
      console.log('ERROR: ', error)
    }
  }

  const setHorseInformation = (data) => {
    setDescriptionContent(data.history)
    const files = data.images.map((data, index) => {
      return {
        uid: index,
        name: "image" + (index + 1),
        type: "image/png",
        status: "done",
        url: data
      }
    })


    const ThreeDFiles360 = {
      uid: 0,
      name: "image-360",
      type: "image/png",
      status: "done",
      url: data.threeDPhoto360
    }
    // const ThreeDFilesRight = {
    //   uid: 1,
    //   name: "image-right",
    //   type: "image/png",
    //   status: "done",
    //   url: data.threeDPhotoRight
    // }
    // const ThreeDFilesLeft = {
    //   uid: 2,
    //   name: "image-left",
    //   type: "image/png",
    //   status: "done",
    //   url: data.threeDPhotoLeft
    // }
    // const ThreeDFilesFront = {
    //   uid: 3,
    //   name: "image-front",
    //   type: "image/png",
    //   status: "done",
    //   url: data.threeDPhotoFront
    // }

    setHorseDescImage(data.description)


    const horseOwnerTempArr = data.horseOwnersWithPercentage.map((data, index) => {
      return {
        key: index,
        id: data.id,
        personId: data.horsePerson__id,
        personName: data.horsePerson__fullName,
        percent: data.ownerPercentage,
      }
    })
    setHorseOwnerData(horseOwnerTempArr)

    const breederTempArr = data.breeder.map((data, index) => {
      return {
        key: index,
        personId: data.id,
        personName: data.fullName
      }
    })
    setBreederData(breederTempArr)

    mainForm.setFieldsValue({
      name: data.name,
      birthDate: data.birthDate,
      sex: data.sex,
      type: data.horseType,
      breed: data.breed,
      colour: data.colour,
      tjkLink: data.tjkLink,
      ykkLink: data.ykkLink,
      tier: data.tier,
      status: data.status,
      price: data.price,
      saleMethod: data.saleMethod,
      head: data.head,
      neck: data.neck,
      frontLeft: data.frontLeft,
      frontRight: data.frontRight,
      backLeft: data.backLeft,
      backRight: data.backRight,
      body: data.body,
      addition: data.addition,
      files: files,
      threeDPhoto360: data.threeDPhoto360 === "" ? [] : [ThreeDFiles360],
      // threeDPhotoRight: data.threeDPhotoRight === "" ? [] : [ThreeDFilesRight],
      // threeDPhotoLeft: data.threeDPhotoLeft === "" ? [] : [ThreeDFilesLeft],
      // threeDPhotoFront: data.threeDPhotoFront === "" ? [] : [ThreeDFilesFront],
      isQualityChecked: data.isQualityChecked,
    })

    setFamily((prevState) => ({
      ...prevState,
      father: data.father,
      fathersFather: data.fathersFather,
      fathersMother: data.fathersMother,
      mother: data.mother,
      mothersFather: data.mothersFather,
      mothersMother: data.mothersMother,
    }))

  }

  useEffect(() => {
    const handleGetHorse = async (id) => {
      props.setRedux({ loading: true })
      const response = await getHorse(id);
      setHorseInformation(response.data)
    }
    if (params.id) {
      if (isNaN(params.id)) {
        history.push("/create-horse")
      }
      // UPDATE PAGE
      handleGetHorse(params.id)
      setIsCreatePage(false)
    } else {
      // CREATE PAGE
      setIsCreatePage(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const onFinish = async (values) => {
    props.setRedux({ loading: true })
    const isUniq = await checkForUniq()
    if (isCreatePage && !isUniq) {
      showNotification({ type: 'error', title: 'Böyle Bir At Mevcut!', description: 'Aynı isimli bir at mevcut lütfen at ismini kontrol edin!' })
      props.setRedux({ loading: false })
      return
    }


    const fileUrls = []
    const ThreeDFiles360 = []
    // const ThreeDFilesRight = []
    // const ThreeDFilesLeft = []
    // const ThreeDFilesFront = []


    if (!isCreatePage) {
      values.files.map(data => {
        if (data.url === undefined) return null
        fileUrls.push(data.url)
        return null
      })

      values.threeDPhoto360.map(data => {
        if (data.url === undefined) return null
        ThreeDFiles360.push(data.url)
        return null
      })
      // values.threeDPhotoRight.map(data => {
      //   if (data.url === undefined) return null
      //   ThreeDFilesRight.push(data.url)
      //   return null
      // })
      // values.threeDPhotoLeft.map(data => {
      //   if (data.url === undefined) return null
      //   ThreeDFilesLeft.push(data.url)
      //   return null
      // })
      // values.threeDPhotoFront.map(data => {
      //   if (data.url === undefined) return null
      //   ThreeDFilesFront.push(data.url)
      //   return null
      // })

    }


    let description = ""
    canvasDraw.current.exportImage("png")
      .then(async (data) => {
        const file = dataURLtoFile(data, "eskal")
        const fData = new FormData()
        fData.append("image", file)
        const res = await uploadFiles(fData);
        const fileUrl = getApiBaseClear() + res.data.image;
        description = fileUrl
      })
      .catch(e => {
        console.log('canvas image upload error: ', e);
      });


    if (values.files) {
      const files_promise = values.files.map(async (file) => {
        const fData = new FormData()
        if (file.originFileObj === undefined) return
        fData.append("image", file.originFileObj)
        try {
          const res = await uploadFiles(fData);
          if (res.status === 201) {
            const fileUrl = getApiBaseClear() + res.data.image;
            fileUrls.push(fileUrl)
          }
        } catch (error) {
          console.log('error: ', error)
        }
        return null;
      })
      await Promise.all(files_promise);
    }

    if (values.threeDPhoto360) {
      const files_promise_3d = values.threeDPhoto360.map(async (file) => {
        const fData = new FormData()
        if (file.originFileObj === undefined) return
        fData.append("image", file.originFileObj)
        try {
          const res = await uploadFiles(fData);
          if (res.status === 201) {
            const fileUrl = getApiBaseClear() + res.data.image;
            ThreeDFiles360.push(fileUrl)
          }
        } catch (error) {
          console.log('error: ', error)
        }
        return null;
      })
      await Promise.all(files_promise_3d);
    }
    // if (values.threeDPhotoRight) {
    //   const files_promise_3d = values.threeDPhotoRight.map(async (file) => {
    //     const fData = new FormData()
    //     if (file.originFileObj === undefined) return
    //     fData.append("image", file.originFileObj)
    //     try {
    //       const res = await uploadFiles(fData);
    //       if (res.status === 201) {
    //         const fileUrl = getApiBaseClear() + res.data.image;
    //         ThreeDFilesRight.push(fileUrl)
    //       }
    //     } catch (error) {
    //       console.log('error: ', error)
    //     }
    //     return null;
    //   })
    //   await Promise.all(files_promise_3d);
    // }
    // if (values.threeDPhotoLeft) {
    //   const files_promise_3d = values.threeDPhotoLeft.map(async (file) => {
    //     const fData = new FormData()
    //     if (file.originFileObj === undefined) return
    //     fData.append("image", file.originFileObj)
    //     try {
    //       const res = await uploadFiles(fData);
    //       if (res.status === 201) {
    //         const fileUrl = getApiBaseClear() + res.data.image;
    //         ThreeDFilesLeft.push(fileUrl)
    //       }
    //     } catch (error) {
    //       console.log('error: ', error)
    //     }
    //     return null;
    //   })
    //   await Promise.all(files_promise_3d);
    // }
    // if (values.threeDPhotoFront) {
    //   const files_promise_3d = values.threeDPhotoFront.map(async (file) => {
    //     const fData = new FormData()
    //     if (file.originFileObj === undefined) return
    //     fData.append("image", file.originFileObj)
    //     try {
    //       const res = await uploadFiles(fData);
    //       if (res.status === 201) {
    //         const fileUrl = getApiBaseClear() + res.data.image;
    //         ThreeDFilesFront.push(fileUrl)
    //       }
    //     } catch (error) {
    //       console.log('error: ', error)
    //     }
    //     return null;
    //   })
    //   await Promise.all(files_promise_3d);
    // }



    const breeder = breederData.map((data) => ({ id: data.personId }))

    const createHorseOwners = async (horse_id, data) => {
      const payload = data.map((data) => {
        return {
          ownerPercentage: data.percent,
          horse: horse_id,
          horsePerson: data.personId,
        }
      })
      await createHorseOwner(payload);
    }

    if (!isCreatePage) {
      const temp = [...horseOwnerData]
      const cleanOwners = temp.filter((data) => !data.id)
      createHorseOwners(params.id, cleanOwners)
    }


    const familyIds = {
      father: family.father ? family.father.id : undefined,
      fathersFather: family.fathersFather ? family.fathersFather.id : undefined,
      fathersMother: family.fathersMother ? family.fathersMother.id : undefined,
      mother: family.mother ? family.mother.id : undefined,
      mothersFather: family.mothersFather ? family.mothersFather.id : undefined,
      mothersMother: family.mothersMother ? family.mothersMother.id : undefined,
    }
    const handleCreateFamily = async (name, value) => {
      const payload = {
        name: value,
      }
      const response = await createHorseFamily(payload)
      // family response altındaki id'yi bir yere kaydet ki at create aşamasında payload'a atabilesin !
      familyIds[name] = response.data.id
    }

    const handleFamilyConditions = (name) => {
      if (family[name]) {
        if (family[name].id) {
          // ZATEN DBYE KAYITLI. familyIds objesi idyi tuttuğu için işlem yapmaya gerek yok
        } else {
          // id alanı yok, demek ki father sadece string ve bu isimle yeni bir family name create et
          handleCreateFamily(name, family[name])
        }
      }
    }

    handleFamilyConditions("father")
    handleFamilyConditions("mother")
    handleFamilyConditions("fathersFather")
    handleFamilyConditions("fathersMother")
    handleFamilyConditions("mothersFather")
    handleFamilyConditions("mothersMother")



    const isStatusShouldBeModel = (
      values.name !== "" &&
      values.birthDate !== "" &&
      values.sex !== "" &&
      values.horseType !== "" &&
      values.breed !== "" &&
      values.colour !== "" && fileUrls.length > 0
    )
    if (isStatusShouldBeModel) {
      values.status = 2
    }

    // ThreeDFilesRight.length > 0 &&
    // ThreeDFilesLeft.length > 0 &&
    // ThreeDFilesFront.length > 0
    const isStatusShouldBeControl = (
      isStatusShouldBeModel &&
      ThreeDFiles360.length > 0
    )
    if (isStatusShouldBeControl) {
      values.status = 3
    }
    if (isStatusShouldBeControl && values.isQualityChecked) {
      values.status = 4
    }

    setTimeout(() => {
      values.history = descriptionContent
      values.description = description
      values.images = [...fileUrls]

      values.threeDPhoto360 = [...ThreeDFiles360][0] || ""
      // values.threeDPhotoRight = [...ThreeDFilesRight][0] || ""
      // values.threeDPhotoLeft = [...ThreeDFilesLeft][0] || ""
      // values.threeDPhotoFront = [...ThreeDFilesFront][0] || ""

      values.breeder = [...breeder]

      const payload = { ...values, ...familyIds }

      const createMainHorse = async () => {
        const response = await createHorse(payload)
        if (response.status === 201) {
          showNotification({ type: 'success', title: 'At Oluşturuldu!', description: 'At başarıyla oluşturuldu!' })
          createHorseOwners(response.data.id, horseOwnerData)
          history.push('/horses')
        }
      }
      const updateMainHorse = async () => {
        const response = await updateHorse(params.id, payload)
        if (response.status === 200) {
          showNotification({ type: 'success', title: 'At Güncellendi!', description: 'At başarıyla güncellendi!' })
          history.push('/horses')
        }
        if (response.status === 400) {
          if (response.data.name) {
            showNotification({ type: 'error', title: 'Böyle Bir At Mevcut!', description: 'Aynı isimli bir at mevcut lütfen at ismini kontrol edin!' })
          }
        }
      }


      if (isCreatePage) {
        try {
          createMainHorse()
        } catch (error) {
          console.log('error: ', error)
          showNotification({ type: 'error', title: 'Upss!', description: 'Something went wrong!' })
        }
      } else {
        // UPDATE İŞLEMLERİ
        try {
          updateMainHorse()
        } catch (error) {
          console.log('error: ', error)
          showNotification({ type: 'error', title: 'Upss!', description: 'Something went wrong!' })
        }
      }

      props.setRedux({ loading: false })
    }, 3000)
  }



  const handleSetFamily = (name, value) => {
    setFamily((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }
  const handleBreedChange = (value) => {
    mainForm.setFieldsValue({
      fatherBreed: value,
      motherBreed: value
    })
  }


  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  const handleAddPerson = (type, personInfo, percent) => {
    if (type === 1 && !percent) {
      showNotification({ type: 'error', title: 'Boş Olamaz!.', description: 'Lütfen Yüzde alanını doldurun!' })
      return
    }
    // The person trying to add is already in db
    if (!type || !personInfo) {
      showNotification({ type: 'error', title: 'Boş Olamaz!.', description: 'Lütfen Kişi tipi ve ad soyad alanlarını doldurun!' })
      return
    }
    if (type === 1) {
      const state = [...horseOwnerData]

      const data = {
        key: state[state.length - 1] ? state[state.length - 1].key + 1 : 0,
        personType: type,
        personName: personInfo.fullName,
        percent: percent,
        personId: personInfo.id
      }
      state.push(data);
      setHorseOwnerData(state)

    } else if (type === 2) {

      const state = [...breederData]
      const data = {
        key: state[state.length - 1] ? state[state.length - 1].key + 1 : 0,
        personType: type,
        personName: personInfo.fullName,
        personId: personInfo.id
      }
      state.push(data);
      setBreederData(state)
    }
  }
  const handleRemoveData = async (record, data, setData) => {
    if (record.id) {
      try {
        await deleteHorseOwner(record.id)
      } catch (error) {
        console.log(error)
      }
    }
    const state = [...data];
    const filteredState = state.filter(data => data.key !== record.key)
    setData(filteredState)

  }
  const breederColumns = [
    {
      title: 'Ad Soyad',
      dataIndex: 'personName',
      key: 'personName',
    },
    {
      title: 'İşlem',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <DeleteOutlined className='cursor-p' onClick={() => handleRemoveData(record, breederData, setBreederData)} />
        </Space>
      ),
      width: 50
    },
  ];

  const ownerColumns = [
    {
      title: 'Ad Soyad',
      dataIndex: 'personName',
      key: 'personName',
    },
    {
      title: 'Yüzde',
      dataIndex: 'percent',
      key: 'percent',
    },
    {
      title: 'İşlem',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <DeleteOutlined className='cursor-p' onClick={() => handleRemoveData(record, horseOwnerData, setHorseOwnerData)} />
        </Space>
      ),
      width: 50
    },
  ];

  const skinColourOptions = skinColourOpt.map((data, index) => (
    <Select.Option key={index} value={data.id}>{data.name}</Select.Option>
  ))
  const breedOptions = breed.map((data, index) => (
    <Select.Option key={index} value={index + 1}>{data}</Select.Option>
  ))
  const sexOptions = sex.map((data, index) => (
    <Select.Option key={index} value={index + 1}>{data}</Select.Option>
  ))
  return (
    <div>
      <Row>
        <Col xs={24} className='bg-white p-20'>
          <Form
            name="horses-form"
            form={mainForm}
            initialValues={{}}
            onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            layout='horizontal'
            labelAlign='left'
            labelCol={{ xs: 8 }}
            wrapperCol={{ xs: 16 }}
            autoComplete="off">
            <Row className='my-20'>
              <Col xs={12} lg={20} align='left'>
                <span className='page-title-style'>At Bilgileri</span>
              </Col>
            </Row>
            <Row gutter={[24, 0]}>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item label="Ad / Name" name="name"
                  rules={[{ required: false, message: 'Lütfen atın adını giriniz!' },]}>
                  <Input size={INPUT_SIZE} placeholder='' />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item label="Doğum Tarihi / Birthday" name="birthDate"
                  rules={[{ required: false, message: 'Lütfen atın doğum tarihini giriniz!' },]}>
                  <Input size={INPUT_SIZE} placeholder='' type='date' />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item label="Cinsiyet / Sex" name="sex"
                  rules={[{ required: false, message: 'Lütfen atın cinsiyetini giriniz!' },]}>
                  <Select size={INPUT_SIZE} allowClear style={{ width: '100%' }} placeholder="">
                    {sexOptions}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item label="Tipi / Type" name="horseType"
                  rules={[{ required: false, message: 'Lütfen atın tipini seçiniz!' },]} initialValue={1}>
                  <Select size={INPUT_SIZE} allowClear style={{ width: '100%' }} placeholder="">
                    <Select.Option value={1}>Gerçek</Select.Option>
                    <Select.Option value={2}>Dijital</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item label="Irk / Breed" name="breed"
                  rules={[{ required: false, message: 'Lütfen atın ırkını seçiniz!' },]}>
                  <Select size={INPUT_SIZE} allowClear style={{ width: '100%' }} placeholder="" onChange={handleBreedChange}>
                    {breedOptions}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item label="Donu / Skin Colour" name="colour"
                  rules={[{ required: false, message: 'Lütfen atın donunu seçiniz!' },]}>
                  <Select size={INPUT_SIZE} allowClear style={{ width: '100%' }} placeholder="">
                    {skinColourOptions}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item label="TJK Link" name="tjkLink"
                  rules={[{ required: false, message: '' }]}>
                  <Input size={INPUT_SIZE} placeholder='' />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item label="Y.K.K Link" name="ykkLink"
                  rules={[{ required: false, message: '' }]}>
                  <Input size={INPUT_SIZE} placeholder='' />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item label="Sınıf / Tier" name="tier"
                  rules={[{ required: false, message: '' }]}>
                  <Input size={INPUT_SIZE} placeholder='' />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item label="Statü / Status" name="status" initialValue={1}
                  rules={[{ required: false, message: '' }]}>
                  <Select size={INPUT_SIZE} style={{ width: '100%' }}>
                    <Select.Option value={1}>New</Select.Option>
                    <Select.Option value={2}>Model</Select.Option>
                    <Select.Option value={3}>Control</Select.Option>
                    <Select.Option value={4}>NFT</Select.Option>
                    <Select.Option value={5}>Ready</Select.Option>
                    <Select.Option value={6}>Published</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item label="Satış Methodu" name="saleMethod" initialValue={1}
                  rules={[{ required: false, message: '' },]}>
                  <Select size={INPUT_SIZE} allowClear style={{ width: '100%' }} placeholder="" onChange={setSaleMethod}>
                    <Select.Option value={1}>Doğrudan Satış / Direct Sale</Select.Option>
                    {/* <Select.Option value={2}>Açık Teklif / Open Tender</Select.Option>
                    <Select.Option value={3}>Açık Arttırma / Auction</Select.Option> */}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item label="Fiyat" name="price"
                  rules={[{ required: saleMethod === 1, message: 'Lütfen fiyat giriniz!' }]}>
                  <Input size={INPUT_SIZE} placeholder='' type={"number"} />
                </Form.Item>
              </Col>
              <Col xs={4}>
                <label>Tarihçe: </label>
              </Col>
              <Col xs={{ span: 20, offset: 0 }} style={{ paddingLeft: 0 }}>
                <JoditEditor
                  ref={editor}
                  value={descriptionContent}
                  config={richTextConfig}
                  tabIndex={1} // tabIndex of textarea
                  onBlur={setDescriptionContent} // preferred to use only this option to update the content for performance reasons
                  onChange={newContent => { }}
                />
              </Col>
              <Col xs={24}>
                <Divider />
              </Col>
              <Col xs={24}>
                <AddOwnerAndBreeder INPUT_SIZE={INPUT_SIZE} handleAddPerson={handleAddPerson} type={1} />
              </Col>
              <Col xs={24}>
                <Table columns={ownerColumns} dataSource={horseOwnerData} size='small' className='small-table' />
              </Col>

              <Col xs={24}>
                <AddOwnerAndBreeder INPUT_SIZE={INPUT_SIZE} handleAddPerson={handleAddPerson} type={2} />
              </Col>
              <Col xs={24}>
                <Table columns={breederColumns} dataSource={breederData} size='small' className='small-table' />
              </Col>
              <Col xs={24}>
                <Divider />
              </Col>
              <Col xs={24}>
                <FamilyInputs INPUT_SIZE={INPUT_SIZE} handleSetFamily={handleSetFamily} family={family} isCreatePage={isCreatePage} />
              </Col>
              <Col xs={24}>
                <Divider />
              </Col>
              <Sketch ref_={canvasDraw} image={horseDescImage} clearCanvas={clearCanvas} />
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item label="Baş" name="head"
                  rules={[{ required: false, message: '' },]}>
                  <Input size={INPUT_SIZE} placeholder='' />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item label="Boyun" name="neck"
                  rules={[{ required: false, message: '' },]}>
                  <Input size={INPUT_SIZE} placeholder='' />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item label="Ön Sol" name="frontLeft"
                  rules={[{ required: false, message: '' },]}>
                  <Input size={INPUT_SIZE} placeholder='' />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item label="Ön Sağ" name="frontRight"
                  rules={[{ required: false, message: '' },]}>
                  <Input size={INPUT_SIZE} placeholder='' />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item label="Art Sol" name="backLeft"
                  rules={[{ required: false, message: '' },]}>
                  <Input size={INPUT_SIZE} placeholder='' />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item label="Art Sağ" name="backRight"
                  rules={[{ required: false, message: '' },]}>
                  <Input size={INPUT_SIZE} placeholder='' />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item label="Vücut" name="body"
                  rules={[{ required: false, message: '' },]}>
                  <Input size={INPUT_SIZE} placeholder='' />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item label="Değişiklikler" name="addition"
                  rules={[{ required: false, message: '' },]}>
                  <Input size={INPUT_SIZE} placeholder='' />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Divider />
              </Col>
              <Col xs={24} align='left'>
                <span className='page-title-style'>At Görselleri</span>
              </Col>
              <Col xs={24} style={{ marginTop: 20 }}>
                <Form.Item label="" name="files" valuePropName='fileList' getValueFromEvent={normFile} rules={[{ required: false, message: '' }]}>
                  <Upload listType="picture-card" onPreview={handlePreview} onChange={onChangeFiles} beforeUpload={beforeUploadFiles}
                    customRequest={customRequest} style={{ whiteSpace: 'nowrap' }}>
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Yükle</div>
                    </div>
                  </Upload>
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Divider />
              </Col>
              <Col xs={24} align='left'>
                <span className='page-title-style'>At 3D Görselleri</span>
              </Col>
              <Col xs={12} style={{ marginTop: 20 }}>
                <Form.Item label="" name="threeDPhoto360" valuePropName='fileList' getValueFromEvent={normFile} rules={[{ required: false, message: '' }]}>
                  <Upload listType="picture-card" onPreview={handlePreview} maxCount={1} onChange={onChangeFiles} beforeUpload={beforeUploadFiles}
                    customRequest={customRequest} style={{ whiteSpace: 'nowrap' }}>
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>360 Görseli Yükle</div>
                    </div>
                  </Upload>
                </Form.Item>
              </Col>
              {/* <Col xs={12} style={{ marginTop: 20 }}>
                <Form.Item label="" name="threeDPhotoRight" valuePropName='fileList' getValueFromEvent={normFile} rules={[{ required: false, message: '' }]}>
                  <Upload listType="picture-card" onPreview={handlePreview} maxCount={1} onChange={onChangeFiles} beforeUpload={beforeUploadFiles}
                    customRequest={customRequest} style={{ whiteSpace: 'nowrap' }}>
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Sağ Görseli Yükle</div>
                    </div>
                  </Upload>
                </Form.Item>
              </Col>
              <Col xs={12} style={{ marginTop: 20 }}>
                <Form.Item label="" name="threeDPhotoLeft" valuePropName='fileList' getValueFromEvent={normFile} rules={[{ required: false, message: '' }]}>
                  <Upload listType="picture-card" onPreview={handlePreview} maxCount={1} onChange={onChangeFiles} beforeUpload={beforeUploadFiles}
                    customRequest={customRequest} style={{ whiteSpace: 'nowrap' }}>
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Sol Görseli Yükle</div>
                    </div>
                  </Upload>
                </Form.Item>
              </Col>
              <Col xs={12} style={{ marginTop: 20 }}>
                <Form.Item label="" name="threeDPhotoFront" valuePropName='fileList' getValueFromEvent={normFile} rules={[{ required: false, message: '' }]}>
                  <Upload listType="picture-card" onPreview={handlePreview} maxCount={1} onChange={onChangeFiles} beforeUpload={beforeUploadFiles}
                    customRequest={customRequest} style={{ whiteSpace: 'nowrap' }}>
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Ön Görseli Yükle</div>
                    </div>
                  </Upload>
                </Form.Item>
              </Col> */}
              <Col xs={24}>
                <Divider />
              </Col>
              <Col xs={24}>
                <Form.Item label="" name="isQualityChecked" valuePropName="checked" rules={[{ required: false, message: '' }]}>
                  <Checkbox>NFT’si üretilecek bu varlığın verileri ve görselleri kontrol edilmiştir</Checkbox>
                </Form.Item>
              </Col>
              <Col xs={24} align='right'>
                <Space size={"large"}>
                  <Button size="large" style={{ backgroundColor: "#FF0000", color: '#FFFFFF' }} onClick={() => history.push("/horses")}>Vazgeç</Button>
                  <Button type='primary' htmlType='submit' size="large">{isCreatePage ? "Kaydet" : "Güncelle"}</Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>

      <Modal visible={previewVisible} title={''} footer={null} onCancel={() => setPreviewVisible(false)}>
        <img
          alt="example"
          style={{
            width: '100%',
          }}
          src={previewImage}
        />
      </Modal>
    </div>
  )
}

const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(NewHorseContent));