import React, { useEffect } from 'react'
import { Button, Col, Popover, Row, Select, Space } from 'antd'
import { ReactSketchCanvas } from 'react-sketch-canvas'
import { useState } from 'react'
import { ArrowLeftOutlined, ArrowRightOutlined, ClearOutlined } from '@ant-design/icons'
import { SketchPicker } from 'react-color'
import { RiPencilLine, RiEraserLine } from "react-icons/ri"

const defaultBlack = {
  "hsl": {
    "h": 0,
    "s": 0,
    "l": 0,
    "a": 1
  },
  "hex": "#000000",
  "rgb": {
    "r": 0,
    "g": 0,
    "b": 0,
    "a": 1
  },
  "hsv": {
    "h": 0,
    "s": 0,
    "v": 0,
    "a": 1
  },
  "oldHue": 0,
  "source": "hsv"
}

const selectedStyle = {
  border: "1px solid #472682",
  borderRadius: '50%',
  padding: '10px 10px',
  backgroundColor: '#472682',
  color: '#FFFFFF',
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
  cursor: 'pointer'
}
const unSelectedStyle = {
  border: "1px solid #472682",
  borderRadius: '50%',
  padding: '10px 10px',
  cursor: 'pointer'
}

const Sketch = (props) => {
  const [penWidth, setPenWidth] = useState(4)
  const [color, setColor] = useState(defaultBlack)
  const [eraseMode, setEraseMode] = useState(false)

  const handleEraseMode = (status) => {
    setEraseMode(status)
  }

  useEffect(() => {
    props.ref_.current.eraseMode(eraseMode)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eraseMode])


  const handleUndo = () => {
    props.ref_.current.undo()
  }
  const handleRedo = () => {
    props.ref_.current.redo()
  }

  const handleColorChange = (color, event) => {
    setColor(color)
    console.log('COLOR: ', color)
  }

  return (
    <>
      <Col xs={4}>
        <Row gutter={[24, 24]}>
          <Col xs={24} align='center'>
            <Space>
              <Popover content={<div>Çiz</div>} title={null}>
              <div style={eraseMode ? unSelectedStyle : selectedStyle} onClick={() => handleEraseMode(false)}>
                <RiPencilLine size={24} />
              </div>
              </Popover>
              <Popover content={<div>Sil</div>} title={null}>
              <div style={eraseMode ? selectedStyle : unSelectedStyle} onClick={() => handleEraseMode(true)}>
                <RiEraserLine size={24} />
              </div>
              </Popover>
            </Space>
          </Col>
          <Col xs={24} align='center'>
            <span>Boyut: </span>
            <Select size='small' style={{ width: '50%' }} placeholder="" value={penWidth} onChange={setPenWidth} >
              <Select.Option value={2}>2</Select.Option>
              <Select.Option value={4}>4</Select.Option>
              <Select.Option value={6}>6</Select.Option>
              <Select.Option value={8}>8</Select.Option>
              <Select.Option value={10}>10</Select.Option>
              <Select.Option value={12}>12</Select.Option>
              <Select.Option value={14}>14</Select.Option>
              <Select.Option value={16}>16</Select.Option>
              <Select.Option value={18}>18</Select.Option>
              <Select.Option value={20}>20</Select.Option>
            </Select>
          </Col>
          <Col xs={24} align='center'>
            <SketchPicker onChange={handleColorChange} width={"90%"} color={color} />
          </Col>
          <Col xs={24} align='center'>
            <Space>
              <Popover content={<div>Geri Al</div>} title={null}>
              <Button onClick={handleUndo} icon={<ArrowLeftOutlined />} size='large' />
              </Popover>
              <Popover content={<div>İleri Al</div>} title={null}>
              <Button onClick={handleRedo} icon={<ArrowRightOutlined />} size='large' />
              </Popover>
            </Space>
          </Col>
          <Col xs={24} align='center'>
            <Space>
              <Popover content={<div>Sıfırla</div>} title={null}>
              <Button onClick={props.clearCanvas} icon={<ClearOutlined />} size='large' />
              </Popover>
            </Space>
          </Col>
        </Row>

      </Col>
      <Col xs={20}>
        <ReactSketchCanvas
          className='m-20'
          style={{ cursor: 'crosshair', marginBottom: 20, border: "1px solid #472682", borderRadius: 20, boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)' }}
          ref={props.ref_}
          strokeWidth={penWidth}
          eraserWidth={penWidth}
          strokeColor={color.hex}
          exportWithBackgroundImage={true}
          backgroundImage={props.image}
          width={'100%'}
          height={650}
        />
      </Col>
    </>
  )
}

export default Sketch