import React, { useState } from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRedux } from "../../redux/byhorses/actions";
import { Button, Checkbox, Col, Form, Input, Row } from 'antd'
import { UserOutlined } from '@ant-design/icons';
import { forgotPassword, logIn } from './api';
import { showNotification } from '../../utils/showNotification';
import { useHistory } from 'react-router-dom';

const Login = (props) => {
  const [showForgotPassword, setShowForgotPassword] = useState(false)
  const history = useHistory();

  const onFinish = async (values) => {
    props.setRedux({ loginLoading: true })
    values.requestType = 1
    try {
      const response = await logIn(values)
      if (response.status === 200) {
        const user = {
          access_token: response.data.access_token,
          refresh_token: response.data.refresh_token,
          id: response.data.user.id
        }
        window.localStorage.setItem('user', JSON.stringify(user))
        props.setRedux({ loginLoading: false })
        history.push('/')
        window.location.reload()
        return
      }
      if (response.data.non_field_errors[0] === "0") {
        showNotification({ type: 'error', title: 'Kullanıcı Aktivasyon Hatası!', description: 'Lütfen öncelikle hesabınızı aktif ediniz!' })
      }
      if (response.data.non_field_errors[0] === "1") {
        showNotification({ type: 'error', title: 'Hata!', description: 'Giriş bilgileri hatalı lütfen tekrar deneyin!' })
      }
    } catch (error) {
      console.log('error : ', error)
        showNotification({ type: 'error', title: 'Hmm!', description: 'Beklenmedik bir hata oluştu!' })
    }

    props.setRedux({ loginLoading: false })
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleForgotPassword = async (values) => {
    props.setRedux({ loginLoading: true })
    try {
      const response = await forgotPassword(values)
      if(response.status === 200){
        showNotification({ type: 'success', title: 'Posta Gönderildi!', description: 'Girdiğiniz e-posta adresine şifre güncelleme maili gönderildi!' })
      }
      if(response.status === 400){
        showNotification({ type: 'error', title: 'Hata!', description: 'Girdiğiniz e-posta ile bir kullanıcı bulunamadı!' })
      }
    } catch (error) {
      console.log('ERROR : ', error)
      showNotification({ type: 'error', title: 'Hmm!', description: 'Beklenmedik bir hata oluştu!' })
    }
    props.setRedux({ loginLoading: false })
  }


  return (
    <div>
      {!showForgotPassword ?
        <>
          <Row className='my-20'>
            <Col xs={24} align='center'>
              <span className='title-style'>Giriş Yap</span>
            </Col>
          </Row>
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off">
            <Row>
              <Col xs={{ span: 18, offset: 3 }}>
                <Form.Item label="" name="email"
                  rules={[
                    { required: true, message: 'Lütfen e-posta giriniz!' },
                    {
                      type: 'email',
                      message: 'Lütfen geçerli bir e-posta giriniz!',
                    }]}>
                  <Input suffix={<UserOutlined />} size='large' placeholder='E-posta' />
                </Form.Item>
              </Col>
              <Col xs={{ span: 18, offset: 3 }}>
                <Form.Item label="" name="password"
                  rules={[{ required: true, message: 'Lütfen şifre giriniz!' }]}>
                  <Input.Password size='large' placeholder='Şifre' />
                </Form.Item>
              </Col>
              <Col xs={{ span: 10, offset: 2 }} md={{ span: 8, offset: 3 }} align='left'>
                <Form.Item name="remember" valuePropName="checked">
                  <Checkbox>Beni Hatırla</Checkbox>
                </Form.Item>
              </Col>
              <Col xs={10} md={{ span: 6, offset: 4 }} align='middle'>
                <Form.Item>
                  <span className='login-link' onClick={() => setShowForgotPassword(true)}>Şifremi Unuttum</span>
                </Form.Item>
              </Col>
              <Col xs={{ span: 18, offset: 3 }}>
                <Form.Item>
                  <Button block size='large' type="primary" htmlType="submit">
                    Giriş
                  </Button>
                </Form.Item>
              </Col>
            </Row>
            <Row className='my-20'>
              <Col xs={24} align='center'>
                <span>Hesabınız yok ise? <span className='login-link' onClick={() => props.setTab(2)}>Hemen kayıt olabilirsiniz!</span></span>
              </Col>
            </Row>
          </Form>
        </> :
        <>
        <Row className='my-20'>
          <Col xs={24} align='center'>
            <span className='title-style'>Şifremi Unuttum</span>
          </Col>
        </Row>
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={handleForgotPassword}
            // onFinishFailed={onFinishFailed}
            autoComplete="off">
            <Row>
              <Col xs={{ span: 18, offset: 3 }}>
                <Form.Item label="" name="email"
                  rules={[
                    { required: true, message: 'Lütfen e-posta giriniz!' },
                    {
                      type: 'email',
                      message: 'Lütfen geçerli bir e-posta giriniz!',
                    }]}>
                  <Input suffix={<UserOutlined />} size='large' placeholder='E-posta' />
                </Form.Item>
              </Col>
              <Col xs={{ span: 18, offset: 3 }}>
                <Form.Item>
                  <Button block size='large' type="primary" htmlType="submit">
                    Şifre Güncelle
                  </Button>
                </Form.Item>
              </Col>
            </Row>
            <Row className='my-20'>
              <Col xs={24} align='center'>
                <span>Şifrenizi biliyor musunuz? <span className='login-link' onClick={() => setShowForgotPassword(false)}>Giriş Yap!</span></span>
              </Col>
            </Row>
          </Form>
        
        </>

      }

    </div>
  )
}

const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(Login));