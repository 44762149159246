/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRedux } from "../../redux/byhorses/actions";
import { Spin, Layout, Menu, Row, Col, Image, Space } from "antd";
import { LoadingOutlined, MenuUnfoldOutlined, MenuFoldOutlined, LogoutOutlined, BookOutlined } from '@ant-design/icons';
import { FaUserCog } from 'react-icons/fa'
import { GiBarn, GiBookshelf, GiExitDoor, GiHorseshoe, GiSportMedal } from 'react-icons/gi'
import logo from '../../assets/images/byhorses-logo/logo.png'
import tempImg from '../../assets/images/icons/user.svg'
import { useHistory } from 'react-router-dom';
import { getUser, isTokenValid } from './api';
import ConnectWallet from './ConnectWallet';

const { Header, Content, Sider } = Layout;


const menuItemStyle = {
  height: 25
}

const MainLayout = (props) => {
  const [user, setUser] = useState({})
  const [siderCollapsed, setSiderCollapsed] = useState(false)
  const [siderSelectedKey, setSiderSelectedKey] = useState('')

  const history = useHistory();

  useEffect(() => {
    props.setRedux({ innerWidth: window.innerWidth })
  }, [window.innerWidth])

  useEffect(() => {
    props.setRedux({ loading: false })
  }, [])

  useEffect(() => {
    if (Object.keys(user).length !== 0) return
    handleGetUser()
  }, [user])

  useEffect(() => {
    if (!props.reduxState?.reRender) return
    handleGetUser()
  }, [props.reduxState?.reRender])

  useEffect(() => {
    const checkTokenValid = async () => {
      const info = JSON.parse(window.localStorage.getItem('user'))
  
      const isValid = await isTokenValid(info.access_token)
      if (isValid) return
      handleLogout()
    }

    checkTokenValid()

  }, [])


  const handleGetUser = async () => {
    const info = JSON.parse(window.localStorage.getItem('user'))
    const user_ = await getUser(info.id)
    setUser(user_.data)
  }

  const handleRouting = (info) => {
    props.setRedux({ loading: true })
    history.push(`/${info.key}`)
    props.setRedux({ loading: false })
    setSiderSelectedKey(info.key)
  }

  const handleRoutingDashboard = () => {
    history.push('/')
    setSiderSelectedKey();
  }

  const handleLogout = async () => {
    props.setRedux({ loading: true })
    window.localStorage.removeItem('user')
    props.setRedux({ loading: false })
    window.location.reload()
  }

  useEffect(() => {
    const parsedUrl = new URL(window.location.href);
    const pathname = parsedUrl.pathname
    const clearPath = pathname.substring(1)
    if (clearPath === '') {
      setSiderSelectedKey()
    } else if(clearPath === 'login'){
      window.location.reload()
    } else if (clearPath.includes('create-horse')) {
      setSiderSelectedKey('horses')
    } else {
      setSiderSelectedKey(clearPath)
    }
  }, [])


  return (
    <div>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={props.reduxState?.loading}>
        <Layout>
          <Sider width={230} className='layout-sider' trigger={null} collapsible collapsed={siderCollapsed}
            style={{
              overflow: 'auto',
              height: '100vh',
              position: 'sticky',
              left: 0,
              top: 0,
              bottom: 0,
            }} >
            <Row>
              <Col className='my-10' xs={22} align='center'>
                <Space className='cursor-p' onClick={handleRoutingDashboard}>
                  <Image width={60} preview={false} style={{ transition: 'all 0.7s' }} src={logo} />
                </Space>
              </Col>
            </Row>
            {siderSelectedKey !== '' &&
              <Menu className='font-w600 font-s12' theme="light" mode="inline" selectedKeys={[siderSelectedKey]}
                items={[
                  {
                    key: 'horses',
                    icon: <GiHorseshoe style={{color: "#472682"}} />,
                    label: 'Atlar',
                    style: menuItemStyle,
                    onClick: handleRouting
                  },
                  {
                    key: 'jockeys',
                    icon: <GiSportMedal style={{color: "#472682"}} />,
                    label: 'Jokeyler',
                    style: menuItemStyle,
                    onClick: handleRouting
                  },
                  {
                    key: 'studs',
                    icon: <GiBarn style={{color: "#472682"}} />,
                    label: 'Haralar',
                    style: menuItemStyle,
                  },
                  {
                    key: 'dictionary',
                    icon: <BookOutlined style={{color: "#472682"}} />,
                    label: 'Sözlük',
                    style: menuItemStyle,
                    onClick: handleRouting
                  },
                  {
                    key: 'nft-library',
                    icon: <GiBookshelf style={{color: "#472682"}} />,
                    label: 'NFT Kütüphanesi',
                    style: menuItemStyle,
                  },
                  {
                    key: 'user-management',
                    icon: <FaUserCog style={{color: "#472682"}} />,
                    label: 'Kullanıcı Yönetimi',
                    style: menuItemStyle,
                  },
                  {
                    key: 'account/logout',
                    icon: <GiExitDoor style={{color: "#472682"}} />,
                    label: 'Çıkış',
                    style: menuItemStyle,
                    onClick: handleLogout
                  },
                ]}
              />
            }
          </Sider>
          <Layout>
            <Header className="layout-header">
              {React.createElement(siderCollapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                style: { fontSize: 18, marginTop: 20, marginLeft: 10 },
                onClick: () => setSiderCollapsed(!siderCollapsed),
              })}
              {props.reduxState?.innerWidth >= 768 &&
                <div className='sticy-display'>
                  <Space size='large'>
                    <ConnectWallet />
                    <Menu style={{ height: '53px' }} mode="horizontal" items={[
                      {
                        key: '2',
                        icon: <Image src={user?.profile?.photo !== null ? user?.profile?.photo : tempImg} width={50} preview={false} style={{ borderRadius: '50%' }} />,
                        // label: '',,
                        children: [
                          {
                            key: 'exit',
                            icon: <LogoutOutlined />,
                            label: 'Çıkış',
                            onClick: handleLogout
                          },
                        ]
                      },
                    ]}>
                    </Menu>
                  </Space>
                </div>
              }
            </Header>
            <Content
              style={{
                margin: props.reduxState?.innerWidth >= 768 ? '48px 0px' : '48px 0px',
                padding: props.reduxState?.innerWidth >= 768 ? '24px 12px 0px 12px' : '24px 10px 24px 10px',
                minHeight: 150,
              }}>
              {(props.reduxState?.innerWidth <= 768 && !siderCollapsed) ? null : props.content}
            </Content>
          </Layout>
        </Layout>
      </Spin>
    </div >
  );
}

const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(MainLayout));