import React, { useEffect, useState } from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRedux } from "../../../redux/byhorses/actions";
import { Col, Row } from 'antd'
import { getHorseFamily } from '../api'
import axios from 'axios'
import HorseSearchInput from '../../../components/input/HorseSearchInput'

const FamilyInputs = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [isSelected, setIsSelected] = useState({})
  const [familyData, setFamilyData] = useState({})
  const [fathersFamily, setFathersFamily] = useState([])
  const [mothersFamily, setMothersFamily] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [familySearchValue, setFamilySearchValue] = useState({})
  const [counter, setCounter] = useState(0)

  useEffect(() => {
    if (props.isCreatePage) return
    setCounter(counter + 1)
    if(counter >= 1) return
    handleSetSearchValue("father", props.family.father?.name)
    handleSetSearchValue("fathersFather", props.family.fathersFather?.name)
    handleSetSearchValue("fathersMother", props.family.fathersMother?.name)
    handleSetSearchValue("mother", props.family.mother?.name)
    handleSetSearchValue("mothersFather", props.family.mothersFather?.name)
    handleSetSearchValue("mothersMother", props.family.mothersMother?.name)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.family])


  const handleGetFamily = async ({ source, searchParam, dataName }) => {
    if (!searchParam) return
    getHorseFamily({ cancelToken: source.token, searchParam: searchParam }).then((response) => {
      handleSetVeri(dataName, response.data)
    }).catch(err => console.log('HATA: ', err))
    props.setRedux({ loading: false })
  }


  const handleSetVeri = (name, value) => {
    setFamilyData((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source()
    handleGetFamily({ source: source, searchParam: familySearchValue.father, dataName: "father" })
    return () => {
      source.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [familySearchValue.father])

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source()
    handleGetFamily({ source: source, searchParam: familySearchValue.fathersFather, dataName: "fathersFather" })
    return () => {
      source.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [familySearchValue.fathersFather])

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source()
    handleGetFamily({ source: source, searchParam: familySearchValue.fathersMother, dataName: "fathersMother" })
    return () => {
      source.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [familySearchValue.fathersMother])


  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source()
    handleGetFamily({ source: source, searchParam: familySearchValue.mother, dataName: "mother" })
    return () => {
      source.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [familySearchValue.mother])

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source()
    handleGetFamily({ source: source, searchParam: familySearchValue.mothersFather, dataName: "mothersFather" })
    return () => {
      source.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [familySearchValue.mothersFather])
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source()
    handleGetFamily({ source: source, searchParam: familySearchValue.mothersMother, dataName: "mothersMother" })
    return () => {
      source.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [familySearchValue.mothersMother])

  const handleIsSelected = (name, value) => {
    setIsSelected((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }
  const handleSetSearchValue = (name, value) => {
    setFamilySearchValue((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  const setValueToState = (name, value) => {
    props.handleSetFamily(name, value)

    if (value.id && name === "father") {
      const fam = familyData.father.filter((dt, i) => dt.id === value.id)[0]
      if (fam.grandFamily === null) return
      handleSetSearchValue("fathersFather", fam.grandFamily.fatherName)
      handleSetSearchValue("fathersMother", fam.grandFamily.motherName)
      let father = { id: fam.grandFamily.fatherId, fullName: fam.grandFamily.fatherName }
      let mother = { id: fam.grandFamily.motherId, fullName: fam.grandFamily.motherName }
      setFathersFamily({
        father: father,
        mother: mother
      })
      setValueToState("fathersFather", father)
      setValueToState("fathersMother", mother)
    }
    if (value.id && name === "mother") {
      const fam = familyData.mother.filter((dt, i) => dt.id === value.id)[0]
      if (fam.grandFamily === null) return
      handleSetSearchValue("mothersFather", fam.grandFamily.fatherName)
      handleSetSearchValue("mothersMother", fam.grandFamily.motherName)
      let father = { id: fam.grandFamily.fatherId, fullName: fam.grandFamily.fatherName }
      let mother = { id: fam.grandFamily.motherId, fullName: fam.grandFamily.motherName }
      setMothersFamily({
        father: father,
        mother: mother
      })
      setValueToState("mothersFather", father)
      setValueToState("mothersMother", mother)
    }
  }


  return (
    <Row align='middle' className='my-20' gutter={[24, 24]}>
      <Col xs={12}>
        <Row gutter={[0, 16]}>
          <Col xs={{ span: 24, offset: 0 }} lg={8}>
            <span>Baba Adı: </span>
          </Col>
          <Col xs={{ span: 24, offset: 0 }} lg={16}>
            <HorseSearchInput data={familyData.father || []} size={props.INPUT_SIZE} placeholder='Baba Adı' defaultSelected={props.family.father} searchValue={(value) => handleSetSearchValue("father", value)}
              setValue={(value) => setValueToState("father", value)} handleIsSelected={(value) => handleIsSelected("father", value)} />
          </Col>
          <Col xs={{ span: 24, offset: 0 }} lg={8}>
            <span>Baba Anne adı: </span>
          </Col>
          <Col xs={{ span: 24, offset: 0 }} lg={16}>
            <HorseSearchInput data={familyData.fathersMother || []} size={props.INPUT_SIZE} placeholder='Baba Anne Adı' defaultSelected={props.family.fathersMother || fathersFamily.mother}
              searchValue={(value) => handleSetSearchValue("fathersMother", value)}
              setValue={(value) => setValueToState("fathersMother", value)} handleIsSelected={(value) => handleIsSelected("fathersMother", value)} />
          </Col>
          <Col xs={{ span: 24, offset: 0 }} lg={8}>
            <span>Baba Baba Adı: </span>
          </Col>
          <Col xs={{ span: 24, offset: 0 }} lg={16}>
            <HorseSearchInput data={familyData.fathersFather || []} size={props.INPUT_SIZE} placeholder='Baba Baba Adı' defaultSelected={props.family.fathersFather || fathersFamily.father}
              searchValue={(value) => handleSetSearchValue("fathersFather", value)}
              setValue={(value) => setValueToState("fathersFather", value)} handleIsSelected={(value) => handleIsSelected("fathersFather", value)} />
          </Col>
        </Row>
      </Col>
      <Col xs={12}>
        <Row gutter={[0, 16]}>
          <Col xs={{ span: 24, offset: 0 }} lg={8}>
            <span>Anne Adı: </span>
          </Col>
          <Col xs={{ span: 24, offset: 0 }} lg={16}>
            <HorseSearchInput data={familyData.mother || []} size={props.INPUT_SIZE} placeholder='Anne Adı' defaultSelected={props.family.mother} searchValue={(value) => handleSetSearchValue("mother", value)}
              setValue={(value) => setValueToState("mother", value)} handleIsSelected={(value) => handleIsSelected("mother", value)} />
          </Col>
          <Col xs={{ span: 24, offset: 0 }} lg={8}>
            <span>Anne Anne Adı: </span>
          </Col>
          <Col xs={{ span: 24, offset: 0 }} lg={16}>
            <HorseSearchInput data={familyData.mothersMother || []} size={props.INPUT_SIZE} placeholder='Anne Anne Adı' defaultSelected={props.family.mothersMother || mothersFamily.mother}
              searchValue={(value) => handleSetSearchValue("mothersMother", value)}
              setValue={(value) => setValueToState("mothersMother", value)} handleIsSelected={(value) => handleIsSelected("mothersMother", value)} />
          </Col>
          <Col xs={{ span: 24, offset: 0 }} lg={8}>
            <span>Anne Baba Adı: </span>
          </Col>
          <Col xs={{ span: 24, offset: 0 }} lg={16}>
            <HorseSearchInput data={familyData.mothersFather || []} size={props.INPUT_SIZE} placeholder='Anne Baba Adı' defaultSelected={props.family.mothersFather || mothersFamily.father}
              searchValue={(value) => handleSetSearchValue("mothersFather", value)}
              setValue={(value) => setValueToState("mothersFather", value)} handleIsSelected={(value) => handleIsSelected("mothersFather", value)} />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}


const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(FamilyInputs));