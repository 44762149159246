/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react'
import { useHistory, useParams, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRedux } from "../../../redux/byhorses/actions";
import { Button, Checkbox, Col, Divider, Form, Input, Modal, Row, Select, Space, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { checkHorseUniq, createJockey, getJockey, updateJockey, uploadFiles } from '../api';
import { getApiBaseClear } from '../../../services/config';
import { showNotification } from '../../../utils/showNotification';

const INPUT_SIZE = "small"

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);

    reader.onerror = (error) => reject(error);
  });


const NewJockeyContent = (props) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [isCreatePage, setIsCreatePage] = useState(true);

  const [saleMethod, setSaleMethod] = useState()

  const [form] = Form.useForm()

  const history = useHistory()
  const params = useParams()

  useEffect(() => {
    const handleGetJockey = async (id) => {
      const response = await getJockey(id)

      const photos = response.data.photos?.map((data, index) => {
        return {
          uid: index,
          name: "image" + (index + 1),
          type: "image/png",
          status: "done",
          url: data
        }
      })

      const threeDPhoto360 = {
        uid: 0,
        name: "image-360",
        type: "image/png",
        status: "done",
        url: response.data.threeDPhoto360
      }
      const threeDPhotoRight = {
        uid: 2,
        name: "image-right",
        type: "image/png",
        status: "done",
        url: response.data.threeDPhotoRight
      }
      const threeDPhotoLeft = {
        uid: 1,
        name: "image-left",
        type: "image/png",
        status: "done",
        url: response.data.threeDPhotoLeft
      }
      const threeDPhotoFront = {
        uid: 3,
        name: "image-front",
        type: "image/png",
        status: "done",
        url: response.data.threeDPhotoFront
      }

      form.setFieldsValue({
        firstName: response.data.firstName,
        lastName: response.data.lastName,
        nickName: response.data.nickName,
        birthPlace: response.data.birthPlace,
        birthDate: response.data.birthDate,
        height: response.data.height,
        weight: response.data.weight,
        jockeyType: response.data.jockeyType,
        price: response.data.price,
        saleMethod: response.data.saleMethod,
        biography: response.data.biography,
        photos: photos,
        threeDPhoto360: response.data.threeDPhoto360 === "" ? [] : [threeDPhoto360],
        threeDPhotoRight: response.data.threeDPhotoRight === "" ? [] : [threeDPhotoRight],
        threeDPhotoLeft: response.data.threeDPhotoLeft === "" ? [] : [threeDPhotoLeft],
        threeDPhotoFront: response.data.threeDPhotoFront === "" ? [] : [threeDPhotoFront],
        isQualityChecked: response.data.isQualityChecked,
      })
      // setFileList(photos)
    }
    if (params.id) {
      if (isNaN(params.id)) {
        history.push("/create-jockey")
      }
      // UPDATE PAGE
      handleGetJockey(params.id)
      setTimeout(() => {
        setIsCreatePage(false)
      }, 1000)
    } else {
      // CREATE PAGE
      setIsCreatePage(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  const checkForUniq = async () => {
    const firstName = form.getFieldValue("firstName")
    const lastName = form.getFieldValue("lastName")
    const nickName = form.getFieldValue("nickName")
    const payload = {
      "firstName": firstName,
      "lastName": lastName,
      "nickName": nickName

    }
    try {
      const check = await checkHorseUniq(payload)
      return check.data.status
    } catch (error) {
      console.log('ERROR: ', error)
    }
  }


  const onFinish = async (values) => {
    props.setRedux({ loading: true })
    const isUniq = await checkForUniq()
    if (isCreatePage && !isUniq) {
      showNotification({ type: 'error', title: 'Böyle Bir Jokey Mevcut!', description: 'Aynı isimli bir jokey mevcut lütfen jokey ismini kontrol edin!' })
      props.setRedux({ loading: false })
      return
    }

    const fileUrls = []

    const ThreeDFiles360 = []
    const ThreeDFilesRight = []
    const ThreeDFilesLeft = []
    const ThreeDFilesFront = []

    if (!isCreatePage) {
      values.photos?.map(data => {
        if (data.url === undefined) return null
        fileUrls.push(data.url)
        return null
      })

      values.threeDPhoto360?.map(data => {
        if (data.url === undefined) return null
        ThreeDFiles360.push(data.url)
        return null
      })
      values.threeDPhotoRight?.map(data => {
        if (data.url === undefined) return null
        ThreeDFilesRight.push(data.url)
        return null
      })
      values.threeDPhotoLeft?.map(data => {
        if (data.url === undefined) return null
        ThreeDFilesLeft.push(data.url)
        return null
      })
      values.threeDPhotoFront?.map(data => {
        if (data.url === undefined) return null
        ThreeDFilesFront.push(data.url)
        return null
      })
    }
    const promise = values.photos?.map(async (file) => {
      if (file.originFileObj === undefined) return
      const fData = new FormData()
      fData.append("image", file.originFileObj)
      try {
        const res = await uploadFiles(fData);
        if (res.status === 201) {
          const fileUrl = getApiBaseClear() + res.data.image;
          fileUrls.push(fileUrl)
        }
      } catch (error) {
        console.log('error: ', error)
      }
    })



    if (values.photos) {
      await Promise.all(promise);
      values.photos = fileUrls
    }

    if (values.threeDPhoto360) {
      const promiseThreeDPhoto360 = values.threeDPhoto360?.map(async (file) => {
        if (file.originFileObj === undefined) return
        const fData = new FormData()
        fData.append("image", file.originFileObj)
        try {
          const res = await uploadFiles(fData);
          if (res.status === 201) {
            const fileUrl = getApiBaseClear() + res.data.image;
            ThreeDFiles360.push(fileUrl)
          }
        } catch (error) {
          console.log('error: ', error)
        }
      })
      await Promise.all(promiseThreeDPhoto360);
    }
    if (values.threeDPhotoRight) {
      const promiseThreeDPhotoRight = values.threeDPhotoRight?.map(async (file) => {
        if (file.originFileObj === undefined) return
        const fData = new FormData()
        fData.append("image", file.originFileObj)
        try {
          const res = await uploadFiles(fData);
          if (res.status === 201) {
            const fileUrl = getApiBaseClear() + res.data.image;
            ThreeDFilesRight.push(fileUrl)
          }
        } catch (error) {
          console.log('error: ', error)
        }
      })
      await Promise.all(promiseThreeDPhotoRight);
    }
    if (values.threeDPhotoLeft) {
      const promiseThreeDPhotoLeft = values.threeDPhotoLeft?.map(async (file) => {
        if (file.originFileObj === undefined) return
        const fData = new FormData()
        fData.append("image", file.originFileObj)
        try {
          const res = await uploadFiles(fData);
          if (res.status === 201) {
            const fileUrl = getApiBaseClear() + res.data.image;
            ThreeDFilesLeft.push(fileUrl)
          }
        } catch (error) {
          console.log('error: ', error)
        }
      })
      await Promise.all(promiseThreeDPhotoLeft);
    }
    if (values.threeDPhotoFront) {
      const promiseThreeDPhotoFront = values.threeDPhotoFront?.map(async (file) => {
        if (file.originFileObj === undefined) return
        const fData = new FormData()
        fData.append("image", file.originFileObj)
        try {
          const res = await uploadFiles(fData);
          if (res.status === 201) {
            const fileUrl = getApiBaseClear() + res.data.image;
            ThreeDFilesFront.push(fileUrl)
          }
        } catch (error) {
          console.log('error: ', error)
        }
      })
      await Promise.all(promiseThreeDPhotoFront);
    }

    values.threeDPhoto360 = ThreeDFiles360[0] || ""
    values.threeDPhotoRight = ThreeDFilesRight[0] || ""
    values.threeDPhotoLeft = ThreeDFilesLeft[0] || ""
    values.threeDPhotoFront = ThreeDFilesFront[0] || ""



    const isStatusShouldBeModel = (
      values.firstName !== "" &&
      values.lastName !== "" &&
      values.nickName !== "" &&
      values.jockeyType !== "" &&
      values.birthPlace !== "" &&
      values.birthDate !== "" &&
      values.weight !== "" &&
      values.height !== "" && fileUrls.length > 0
    )
    if (isStatusShouldBeModel) {
      values.status = 2
    }
    const isStatusShouldBeControl = (
      isStatusShouldBeModel &&
      ThreeDFiles360.length > 0 &&
      ThreeDFilesRight.length > 0 &&
      ThreeDFilesLeft.length > 0 &&
      ThreeDFilesFront.length > 0
    )
    if (isStatusShouldBeControl) {
      values.status = 3
    }
    if (isStatusShouldBeControl && values.isQualityChecked) {
      values.status = 4
    }

    if (isCreatePage) {
      try {
        const createJockey_ = await createJockey(values)
        if (createJockey_.status === 201) {
          history.push('/jockeys')
        }
      } catch (error) {
        console.log(error)
      }
    } else {
      try {
        const updateJockey_ = await updateJockey(params.id, values)
        if (updateJockey_.status === 400) {
          if (updateJockey_.data.nickName || updateJockey_.data.non_field_errors) {
            showNotification({ type: 'error', title: 'Böyle Bir Jokey Mevcut!', description: 'Aynı isimli bir jokey mevcut lütfen jokey ismini kontrol edin!' })
          }
        }
        if (updateJockey_.status === 200) {
          history.push('/jockeys')
        }
      } catch (error) {
        console.log(error)
      }
    }



    props.setRedux({ loading: false })
  }


  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };
  const onChangeFiles = (info) => {
    if (info.file.status === 'uploading') {
      info.file.status = 'done';
    }
    if (info.file.status === 'done') {
    }
  }
  const beforeUploadFiles = (file) => true

  // Bu fonksiyonu kaldırmayın hata verir!
  const customRequest = (opts) => { }

  return (
    <div>
      <Row>
        <Col xs={24} className='bg-white p-20'>
          <Form
            name="horses-form"
            form={form}
            initialValues={{}}
            onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            layout='horizontal'
            labelAlign='left'
            labelCol={{ xs: 8 }}
            wrapperCol={{ xs: 16 }}
            autoComplete="off">
            <Row className='my-20'>
              <Col xs={12} lg={20} align='left'>
                <span className='page-title-style'>Jokey Bilgileri</span>
              </Col>
            </Row>
            <Row gutter={[24, 0]}>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item label="Ad" name="firstName"
                  rules={[{ required: true, message: 'Lütfen ad girin' },]}>
                  <Input size={INPUT_SIZE} placeholder='' />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item label="Soyad" name="lastName"
                  rules={[{ required: true, message: 'Lütfen soyad girin' },]}>
                  <Input size={INPUT_SIZE} placeholder='' />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item label="Lakap" name="nickName"
                  rules={[{ required: true, message: 'Lütfen lakap girin' },]}>
                  <Input size={INPUT_SIZE} placeholder='' />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item label="Tipi" name="jockeyType"
                  rules={[{ required: false, message: 'Lütfen jokeyin tipini seçiniz!' },]} initialValue={1}>
                  <Select size={INPUT_SIZE} allowClear style={{ width: '100%' }} placeholder="">
                    <Select.Option value={1}>Gerçek</Select.Option>
                    <Select.Option value={2}>Dijital</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item label="Doğum Yeri" name="birthPlace"
                  rules={[{ required: false, message: '' },]}>
                  <Input size={INPUT_SIZE} placeholder='' />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item label="Doğum Tarihi" name="birthDate"
                  rules={[{ required: false, message: '' },]}>
                  <Input size={INPUT_SIZE} placeholder='' type={'date'} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item label="Boy" name="height"
                  rules={[{ required: false, message: '' },]}>
                  <Input size={INPUT_SIZE} placeholder='' />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item label="Kilo" name="weight"
                  rules={[{ required: false, message: '' },]}>
                  <Input size={INPUT_SIZE} placeholder='' />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item label="Sınıf / Tier" name="tier"
                  rules={[{ required: false, message: '' }]}>
                  <Input size={INPUT_SIZE} placeholder='' />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item label="Statü / Status" name="status" initialValue={1}
                  rules={[{ required: false, message: '' }]}>
                  <Select size={INPUT_SIZE} style={{ width: '100%' }}>
                    <Select.Option value={1}>New</Select.Option>
                    <Select.Option value={2}>Model</Select.Option>
                    <Select.Option value={3}>Control</Select.Option>
                    <Select.Option value={4}>NFT</Select.Option>
                    <Select.Option value={5}>Ready</Select.Option>
                    <Select.Option value={6}>Published</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item label="Satış Methodu" name="saleMethod"
                  rules={[{ required: false, message: '' },]}>
                  <Select size={INPUT_SIZE} allowClear style={{ width: '100%' }} placeholder="" onChange={setSaleMethod}>
                    <Select.Option value={1}>Doğrudan Satış / Direct Sale</Select.Option>
                    <Select.Option value={2}>Açık Teklif / Open Tender</Select.Option>
                    <Select.Option value={3}>Açık Arttırma / Auction</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item label="Fiyat" name="price"
                  rules={[{ required: saleMethod === 1, message: 'Lütfen fiyat giriniz!' }]}>
                  <Input size={INPUT_SIZE} placeholder='' type={"number"} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={24}>
                <Form.Item label="Biyografi" name="biography"
                  labelCol={{ xs: 4 }}
                  wrapperCol={{ xs: 20 }}
                  rules={[{ required: false, message: '' },]}>
                  <Input.TextArea size={INPUT_SIZE} placeholder='' rows={3} />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Divider />
              </Col>
              <Col xs={24} align='left'>
                <span className='page-title-style'>Jokey Görselleri</span>
              </Col>
              <Col xs={24} style={{ marginTop: 20 }}>
                <Form.Item label="" name="photos" valuePropName='fileList' getValueFromEvent={normFile} rules={[{ required: false, message: '' }]}>
                  <Upload listType="picture-card" onPreview={handlePreview} onChange={onChangeFiles} beforeUpload={beforeUploadFiles}
                    customRequest={customRequest} style={{ whiteSpace: 'nowrap' }}>
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Yükle</div>
                    </div>
                  </Upload>
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Divider />
              </Col>
              <Col xs={24} align='left'>
                <span className='page-title-style'>Jokey 3D Görselleri</span>
              </Col>
              <Col xs={12} style={{ marginTop: 20 }}>
                <Form.Item label="" name="threeDPhoto360" valuePropName='fileList' getValueFromEvent={normFile} rules={[{ required: false, message: '' }]}>
                  <Upload listType="picture-card" onPreview={handlePreview} maxCount={1} onChange={onChangeFiles} beforeUpload={beforeUploadFiles}
                    customRequest={customRequest} style={{ whiteSpace: 'nowrap' }}>
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>360 Görseli Yükle</div>
                    </div>
                  </Upload>
                </Form.Item>
              </Col>
              <Col xs={12} style={{ marginTop: 20 }}>
                <Form.Item label="" name="threeDPhotoRight" valuePropName='fileList' getValueFromEvent={normFile} rules={[{ required: false, message: '' }]}>
                  <Upload listType="picture-card" onPreview={handlePreview} maxCount={1} onChange={onChangeFiles} beforeUpload={beforeUploadFiles}
                    customRequest={customRequest} style={{ whiteSpace: 'nowrap' }}>
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Sağ Görseli Yükle</div>
                    </div>
                  </Upload>
                </Form.Item>
              </Col>
              <Col xs={12} style={{ marginTop: 20 }}>
                <Form.Item label="" name="threeDPhotoLeft" valuePropName='fileList' getValueFromEvent={normFile} rules={[{ required: false, message: '' }]}>
                  <Upload listType="picture-card" onPreview={handlePreview} maxCount={1} onChange={onChangeFiles} beforeUpload={beforeUploadFiles}
                    customRequest={customRequest} style={{ whiteSpace: 'nowrap' }}>
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Sol Görseli Yükle</div>
                    </div>
                  </Upload>
                </Form.Item>
              </Col>
              <Col xs={12} style={{ marginTop: 20 }}>
                <Form.Item label="" name="threeDPhotoFront" valuePropName='fileList' getValueFromEvent={normFile} rules={[{ required: false, message: '' }]}>
                  <Upload listType="picture-card" onPreview={handlePreview} maxCount={1} onChange={onChangeFiles} beforeUpload={beforeUploadFiles}
                    customRequest={customRequest} style={{ whiteSpace: 'nowrap' }}>
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Ön Görseli Yükle</div>
                    </div>
                  </Upload>
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Divider />
              </Col>
              <Col xs={24}>
                <Form.Item label="" name="isQualityChecked" valuePropName="checked" rules={[{ required: false, message: '' }]}>
                  <Checkbox>NFT’si üretilecek bu varlığın verileri ve görselleri kontrol edilmiştir</Checkbox>
                </Form.Item>
              </Col>
              <Col xs={24} align='right'>
                <Space size={"large"}>
                  <Button size="large" style={{ backgroundColor: "#FF0000", color: '#FFFFFF' }} onClick={() => history.push("/horses")}>Vazgeç</Button>
                  <Button type='primary' htmlType='submit' size="large">{isCreatePage ? "Kaydet" : "Güncelle"}</Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>

      <Modal visible={previewVisible} title={''} footer={null} onCancel={() => setPreviewVisible(false)}>
        <img
          alt="example"
          style={{
            width: '100%',
          }}
          src={previewImage}
        />
      </Modal>
    </div>
  )
}

const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(NewJockeyContent));