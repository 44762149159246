import React, { useEffect, useState } from 'react'
import { Button, Col, Input, Row } from 'antd'
import SearchInput from '../../../components/input/SearchInput'
import axios from 'axios'
import { createHorsePerson, getPersons } from '../api'

const AddOwnerAndBreeder = (props) => {
  const [person, setPerson] = useState("")
  const [isSelected, setIsSelected] = useState()
  const [persons, setPersons] = useState([])
  const [serviceCounter, setServiceCounter] = useState(0) // this counter will change everytime when a new person created
  const [percent, setPercent] = useState(100)

  const [addCounter, setAddCounter] = useState(0)


  useEffect(() => {
    const getPersons_ = async ({ source }) => {
      const response = await getPersons({ cancelToken: source.token })
      setPersons(response.data)
    }
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source()
    getPersons_({ source })
    return () => {
      source.cancel()
    }
  }, [serviceCounter])



  const setPersonToState = (fullname) => {
    setPerson(fullname)
  }

  const handleIsSelected = (isSelected_) => {
    setIsSelected(isSelected_)
  }

  const resetFields = () => {
    setPercent(100)
    setAddCounter(addCounter+1)
  }


  const handleClick = async () => {
    const personInfo = {
      fullName: "",
      id: 0
    }
    if (isSelected === false) {
      // Person is not in db. create person first
      const response = await createHorsePerson({ fullName: person })
      setServiceCounter(serviceCounter + 1)
      personInfo.fullName = response.data.fullName;
      personInfo.id = response.data.id
    } else {
      personInfo.fullName = person.fullName;
      personInfo.id = person.id
    }
    props.handleAddPerson(props.type, personInfo, percent)
    resetFields()
  }

  return (
    <Row align='middle' className='my-20'>
      <Col xs={{ span: 24, offset: 0 }} lg={4}>
        {/* <Select size={props.INPUT_SIZE} style={{ width: '60%' }} value={type} >
          <Select.Option value={1}>Sahibi</Select.Option>
          <Select.Option value={2}>Yetiştirici</Select.Option>
        </Select> */}
        <span>{props.type === 1 ? "Sahibi / Owner: " : "Yetiştirici / Breeder: "}</span>
      </Col>
      <Col xs={{ span: 24, offset: 0 }} lg={8}>
        <SearchInput data={persons} size={props.INPUT_SIZE} placeholder='Ad Soyad' 
        setValue={setPersonToState} handleIsSelected={handleIsSelected} addCounter={addCounter} />
      </Col>
      <Col xs={{ span: 24, offset: 0 }} lg={{span: 6, offset: 1}} align='left'>
        {props.type === 1 &&
          <Input placeholder='Yüzde' suffix={"%"} 
          size={props.INPUT_SIZE} style={{width: "50%"}} type={"number"}
          value={percent} onChange={(e) => setPercent(e.target.value)} />
        }
      </Col>
      <Col xs={{ span: 24, offset: 0 }} lg={4} align='right'>
        <Button type='primary' onClick={handleClick}>Ekle</Button>
      </Col>
    </Row>
  )
}

export default AddOwnerAndBreeder