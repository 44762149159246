export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);

    reader.onerror = (error) => reject(error);
  });

export const dataURLtoFile = (dataurl, filename) => {

  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}


export const INPUT_SIZE = "small"


export const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

export const beforeUploadFiles = (file) => true

// Bu fonksiyonu kaldırmayın hata verir!
export const customRequest = (opts) => { }

export const onChangeFiles = (info) => {
  if (info.file.status === 'uploading') {
    info.file.status = 'done';
  }
  if (info.file.status === 'done') {
  }
}

export const richTextConfig = {
  readonly: false, // all options from https://xdsoft.net/jodit/doc/
  // enableDragAndDropFileToEditor: true,
  placeholder: 'Lütfen uzun bir açıklama metini yazınız',
  buttons: "bold,italic,underline,strikethrough,ul,ol,font,fontsize,paragraph,superscript,subscript,spellcheck,copyformat,cut,copy,paste,selectall"
}

export const skinColour = ["Al / Chestnut", "Yanık Al / Liver Chestnut", "Açık Al / Flaxen Chestnut", "Kır / Gray", "Demir Kır / Dopple Gray", "Kızıl Kır / Rose Gray", "Doru / Bay", "Koyu Doru / Dark Bay", "Yağız / Black", "Kula / Roan"]
export const skinColourOpt = [
  {
    id: 7,
    name: "Doru / Bay"
  },
  {
    id: 8,
    name: "Koyu Doru / Dark Bay"
  },
  {
    id: 1,
    name: "Al / Chestnut"
  },
  {
    id: 2,
    name: "Yanık Al / Liver Chestnut"
  },
  {
    id: 3,
    name: "Açık Al / Flaxen Chestnut"
  },
  {
    id: 4,
    name: "Kır / Gray"
  },
  {
    id: 5,
    name: "Demir Kır / Dopple Gray"
  },
  {
    id: 6,
    name: "Kızıl Kır / Rose Gray"
  },
  {
    id: 9,
    name: "Yağız / Black"
  },
  {
    id: 10,
    name: "Kula / Roan"
  }]
export const breed = ["İngiliz / Thorougbred", "Arap / Arabian"]
export const sex = ["Dişi / Filly", "Erkek / Colt", "Kısrak / Mare", "Aygır / Stallion", "İğdiş / Gelding"]



