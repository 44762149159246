import React, { useEffect, useState } from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRedux } from "../../redux/byhorses/actions";
import { Button, Col, Dropdown, Image, Menu, Modal, Row } from 'antd'
import metamask from "../../assets/images/icons/metamask.png"
import walletConnect from "../../assets/images/icons/wallet-connect.png"
import { comingSoon } from '../../utils/ComingSoon'
import { showNotification } from '../../utils/showNotification';
import Web3 from 'web3';


const WalletCard = (props) => {
  return (
    <div className='connect-wallet-card' onClick={props.onClick}>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Image preview={false} width={75} src={props.image} />
        </Col>
        <Col xs={24} align='center'>
          <span>{props.walletName}</span>
        </Col>
      </Row>
    </div>
  )
}

const ConnectWallet = (props) => {

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [account, setAccount] = useState(null)

  const closeModal = () => setIsModalVisible(false)
  const openModal = () => setIsModalVisible(true)

  useEffect(() => {
    const isConnected = async () => {
      const accounts = await window.ethereum.request({ method: 'eth_accounts' });
      if (accounts.length) {
        window.localStorage.setItem("account", accounts[0])
        setAccount(accounts[0])
        props.setRedux({ metamaskAccount: accounts[0] })
      } else {
        console.log("Metamask is not connected");
      }
    }
    if(window.localStorage.getItem("account")){
      isConnected()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const connectMetamask = async () => {
    if (window.ethereum && window.ethereum.isMetaMask) {
      try {
        const web3 = new Web3(Web3.givenProvider || "http://localhost:4000/");
        const accounts = await web3.eth.requestAccounts();
        window.localStorage.setItem("account", accounts[0])
        setAccount(accounts[0])
        props.setRedux({ metamaskAccount: accounts[0] })
        closeModal()
      } catch (ex) {
        console.log('CONNECT EX : ', ex)
        showNotification({
          type: 'error',
          title: 'Something Went Wrong!',
          description: "Couldn't connect to metamask"
        })
      }
    } else {
      if (props.reduxState?.innerWidth < 600) {
        showNotification({
          type: 'error',
          title: 'Not Metamask Browser!',
          description: 'You need to open webpage in metamask application'
        })
      } else {
        showNotification({
          type: 'error',
          title: 'Not Installed!',
          description: 'You need to install metamask first!'
        })
      }
    }
  }

  const disconnectMetamask = async () => {
    try {
      // const web3 = new Web3(Web3.givenProvider || "http://localhost:4040/");
      // await web3.eth.currentProvider.disconnect();
      window.localStorage.removeItem("account")
      setAccount(null)
      props.setRedux({ metamaskAccount: null })
    } catch (ex) {
      console.log('CONNECT EX : ', ex)
    }
  }

  const showAccount = () => {
    const first = account.slice(0, 4)
    const last = account.slice(-4)
    return first + "...." + last
  }

  return (
    <>
      {account ?
        <Dropdown trigger={props.mobile ? ['click'] : undefined} overlay={<Menu className='wallet-dropdown'
          items={[
            {
              key: '0',
              label: (
                <div onClick={disconnectMetamask}>
                  Bağlantıyı Kes
                </div>
              ),
            },
          ]}
        />} placement="bottom">
          <Button type="primary" size='large'>{showAccount()}</Button>
        </Dropdown>
        :
        <Button type="primary" size='large' onClick={openModal}>Cüzdana Bağlan</Button>
      }

      <Modal title={''} className='connect-wallet-modal'
        visible={isModalVisible} onOk={closeModal} onCancel={closeModal} footer={false}>
        <Row gutter={[24, 24]}>
          <Col xs={24} align='center'>
            <span className='font-1rem'>Cüzdana Bağlan</span>
          </Col>
          <Col xs={24} lg={12} align='center'>
            <WalletCard image={metamask} walletName={"Metamask"} onClick={connectMetamask} />
          </Col>
          <Col xs={24} lg={12} align='center'>
            <WalletCard image={walletConnect} walletName={"WalletConnect"} onClick={() => comingSoon()} />
          </Col>
        </Row>
      </Modal>
    </>
  )
}

const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(ConnectWallet));