import Web3 from "web3";
import { mainContractAbi, mainContractAddress } from "../abi/main";

class MainService {
  constructor() {
    const mainAbi = mainContractAbi;
    const mainAddress = mainContractAddress;


    let w3 = new Web3(window.ethereum);

    this.mainContract = new w3.eth.Contract(mainAbi, mainAddress)

  }

  createHorses = async (values) => {
    let { id, uri, totalSupply, sender } = values;

    let result = await this.mainContract.methods.createHorses(id, uri, totalSupply).encodeABI()

    let tx = {
      from: sender,
      to: mainContractAddress,
      data: result,
      nonce: "0x00",
    }

    let txHash = await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [tx],
    })
    return txHash

  }

  mint = async (values) => {
    let { account, id, amount, sender } = values;

    let result = await this.mainContract.methods.mint(account, id, amount).encodeABI()

    let tx = {
      from: sender,
      to: mainContractAddress,
      data: result,
      nonce: "0x00",
    }

    let txHash = await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [tx],
    })

    return txHash;
  }

  burn = async (values) => {
    let { from, id, amount } = values;
    console.log('VALUES: ', values)

    let result = await this.mainContract.methods.burn(from, id, amount).encodeABI()
    console.log('RESULT: ', result)
    let tx = {
      from: from,
      to: mainContractAddress,
      data: result,
      nonce: "0x00"
    }
    console.log('TX : ', tx)
    let txHash = await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [tx],
    })
    console.log('TXHASH : ', txHash)

    return txHash;
  }



  isOwner = async (values) => {
    let { account } = values;
    let owner = await this.mainContract.methods.owner().call()
    if (owner.toLowerCase() === account.toLowerCase()) {
      return true
    } else {
      return false
    }
  }
}

export default new MainService();