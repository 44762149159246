import { createRoot } from 'react-dom/client'
import App from "./App";


const routes = (
  <App />
);

const container = document.querySelector("#root")

const root = createRoot(container)

root.render(routes)
