import React from "react";
import { Provider } from "react-redux";
import { configureStore } from "./redux/store";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "../src/assets/css/styles.less"
import PrivateRoute from "./routes/PrivateRoute.js";
import { ConfigProvider } from 'antd';
import trTR from 'antd/lib/locale/tr_TR';
import Page404 from "./pages/404/404";
import ScrollToTop from "./routes/ScrollToTop";
import LoginLayout from "./pages/login/LoginLayout";
import ChangePassword from "./pages/public/ChangePassword";
import CreatePassword from "./pages/public/CreatePassword";
import MainLayout from "./components/layout/MainLayout";
import DashboardContent from "./pages/dashboard/DashboardContent";
import HorsesContent from "./pages/horses/HorsesContent";
import NewHorseContent from "./pages/horses/newHorse/NewHorseContent";
import JockeysContent from "./pages/jockeys/JockeysContent";
import NewJockeyContent from "./pages/jockeys/newJockey/NewJockeyContent";
import DictionaryContent from "./pages/dictionary/DictionaryContent";

const App = () => {
  return (
    <Provider store={configureStore()}>
      <BrowserRouter>
        <ConfigProvider locale={trTR}>
          <ScrollToTop>
            {window.location.pathname.includes('/login') || window.location.pathname.includes('/authentication') ?
              <Switch>
                <Route exact path="/login" component={LoginLayout} />
                <Route exact path="/authentication/reset/:uid/:token" component={ChangePassword} />
                <Route exact path="/authentication/create-password/:token" component={CreatePassword} />
              </Switch> :
              <MainLayout page='Anasayfa' content={
                <Switch>
                  <PrivateRoute exact path="/" component={DashboardContent} />
                  <PrivateRoute exact path="/horses" component={HorsesContent} />
                  <PrivateRoute exact path="/create-horse" component={NewHorseContent} />
                  <PrivateRoute exact path="/update-horse/:id" component={NewHorseContent} />
                  <PrivateRoute exact path="/jockeys" component={JockeysContent} />
                  <PrivateRoute exact path="/create-jockey" component={NewJockeyContent} />
                  <PrivateRoute exact path="/update-jockey/:id" component={NewJockeyContent} />
                  <PrivateRoute exact path="/dictionary" component={DictionaryContent} />
                  <Route path="*">
                    <Page404 />
                  </Route>
                </Switch>} />
            }
          </ScrollToTop>
        </ConfigProvider>
      </BrowserRouter>
    </Provider>
  );
}
export default App;
