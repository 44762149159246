import moment from "moment"

const pluralize = (str, n) => n > 1 ? `${n} ${str}` : n === 0 ? '' :`${n} ${str}`

export const calcAge = (dob) => {
  const age = moment.duration(moment().diff(moment(dob)))
  const ageInYears = Math.floor(age.asYears())
  const ageInMonths = Math.floor(age.asMonths())
  // const ageInDays = Math.floor(age.asDays())

  if (age < 0)
    return

  let pluralYears = pluralize('yıl', ageInYears)
  let pluralDays = pluralize('gün', age.days())

  if (ageInYears < 18) {
    if (ageInYears >= 1) {
      return `${pluralYears} ${pluralize('ay', age.months())}`
    } else if (ageInYears < 1 && ageInMonths >= 1) {
      return `${pluralize('ay', ageInMonths)} ${pluralDays}`
    } else {
      return pluralDays
    }
  } else {
    return pluralYears
  }

}