import { protectedApi } from '../../services/axios'

export const getHorsesCount = async ({cancelToken}) => {
  try {
    const response = await protectedApi.get('management/horses?limit=1', {cancelToken: cancelToken});
    return response;
  } catch (error) {
    return error.response;
  }
}
export const getUsersCount = async ({cancelToken}) => {
  try {
    const response = await protectedApi.get('/management/users?limit=1', {cancelToken: cancelToken});
    return response;
  } catch (error) {
    return error.response;
  }
}